@import "../../globals/commons.scss";

.services {
    margin-bottom: 5.9375rem;
    padding-block: 3rem;
    background-size: cover;
    background-image: url(../../assets/img/servicos-bg.jpg);
}

.services .swiper {
    --swiper-navigation-top-offset: 58%;
}

.services .title {
    --title-color: var(--white-prime);
    margin-bottom: 1.25rem;
}

.services .editor {
    color: var(--white-prime);
    font-family: var(--ff-base);
    font-size: clamp(1.5rem, 1.0178vw + 1.2615rem, 1.75rem);
    font-weight: 600;
    line-height: 130%;
    text-transform: uppercase;
}

.services .editor p {
    text-wrap: balance;
}

.services .services__slider {
    border: 2px solid var(--black-prime);
    background-color: var(--white-prime);
    transition-property: background-color filter;
    transition-duration: 0.15s;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.services .services__slider:hover {
    filter: drop-shadow(0 16px 36px rgba(0, 0, 0, 0.44));
    background-color: var(--gray-light);
}

.services .col-text {
    grid-column: 2 / span 4;
    padding-top: 6.625rem;
    margin-right: -1.25rem;
}

.title {
    --title-color: var(--blue-active);
    color: var(--title-color);
    font-size: 0.75rem;
    font-weight: 600;
    letter-spacing: 0.3em;
    text-transform: uppercase;
    vertical-align: baseline;
}

.title:after {
    content: "";
    display: inline-flex;
    width: 1rem;
    height: 0.125rem;
    background-color: currentColor;
}

.editor {
    font-family: var(--ff-accent);
}

.services .col-slider {
    grid-column: 7 / span 6;
}

.services .carousel-item {
    width: 100%;
    height: 360px;
}

.carousel-control-prev,
.carousel-control-next {
    justify-content: space-around;
}

.carousel-control-prev-icon {
    background-image: url(../../assets/img/seta-esquerda.png);
    width: 1rem;
    height: 1rem;
}

.carousel-control-next-icon {
    background-image: url(../../assets//img/seta-direita.png);
    width: 1rem;
    height: 1rem;
}

.service-card {
    background: none;
}

.service-card .service-card__media {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 2px solid var(--black-prime);
}

.service-card .service-card__media img {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.service-card .service-card__content {
    padding: clamp(1rem, 7.1247vw + -0.6698rem, 2.75rem) clamp(1.5rem, 14.2494vw + -1.8397rem, 5rem);
    height: 645px;
}

.service-card .service-card__title {
    margin-bottom: clamp(1rem, 4.0712vw + 0.0458rem, 2rem);
    color: var(--blue-active);
    font-size: clamp(1.5rem, 2.0356vw + 1.0229rem, 2rem);
    font-weight: 600;
    line-height: 110%;
    text-transform: uppercase;
    text-wrap: balance;
}

.service-card .service-card__subtitle {
    margin-bottom: clamp(0.875rem, 2.5445vw + 0.2786rem, 1.5rem);
    color: var(--black-prime);
    font-family: var(--ff-accent);
    font-size: clamp(1rem, 2.0356vw + 0.5229rem, 1.5rem);
    font-weight: 600;
    line-height: 130%;
}

.swiper-slide img {
    shape-margin: 1rem;
    width: 100%;
    height: auto;
    font-style: italic;
    background-repeat: no-repeat;
    background-size: cover;
    vertical-align: middle;
}

.servicos-paginacao {
    position: relative;
    display: flex;
    border-top: none;
    border-bottom: 2px solid var(--black-prime);
    border-top: 2px solid var(--black-prime);
}

.servicos-paginacao-bullet {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    width: auto;
    height: auto;
    min-height: clamp(1.3125rem, 0.7634vw + 1.1336rem, 1.5rem);
    opacity: 1;
    color: var(--black-prime);
    font-size: clamp(0.875rem, 0.5089vw + 0.7557rem, 1rem);
    font-weight: 400;
    background-color: var(--white-prime);
    border-right: 2px solid var(--black-prime);
}

.servicos-paginacao-bullet-active {
    background-color: var(--blue-light);
}

.swiper-horizontal > .servicos-paginacao-bullets,
.servicos-paginacao-bullets.servicos-paginacao-horizontal,
.servicos-paginacao-custom,
.servicos-paginacao-fraction {
    display: flex;
    justify-content: center;
}

@media only screen and (min-width: 768px) {
    .service-card .service-card__title {
        line-height: 120%;
    }

    .services {
        margin-bottom: clamp(5.9375rem, 6.6964vw + 2.7232rem, 8.75rem);
    }

    .services .title {
        margin-bottom: clamp(1.25rem, 1.4881vw + 0.5357rem, 1.875rem);
    }

    .services .editor {
        font-size: clamp(1.75rem, 1.7857vw + 0.8929rem, 2.5rem);
    }
}

@media only screen and (min-width: 1024px) {
    .wrapper {
        padding-inline: calc(var(--gutter) * 2);
    }

    .swiper {
        --swiper-navigation-sides-offset: 1.875rem;
    }

    .title {
        font-size: clamp(0.75rem, 0.5952vw + 0.4643rem, 1rem);
    }

    .services .col-text {
        grid-column: 2 / span 4;
        padding-top: 6.625rem;
        margin-right: -1.25rem;
    }

    .services .col-slider {
        grid-column: 6 / span 7;
    }
}

@media only screen and (min-width: 1600px) {
    .services .col-slider {
        grid-column: 7 / span 5;
    }
}

@media only screen and (min-width: 1023px) and (max-width: 1124px) {
    .services .col-slider {
        grid-column: 6 / span 8;
    }
}

@media only screen and (max-width: 1023px) {
    .services .editor br {
        display: none;
    }

    .services .col-text {
        padding-inline: 1.25rem;
        margin-bottom: 3rem;
    }
}

@media only screen and (max-width: 767.98px) {
    .services {
        padding-bottom: 5.625rem;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1023.98px) {
    .services .col-slider {
        padding-inline: 1.5rem;
    }
}
