
#container-manutencao {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    background-color: #f4f4f4;
}

.container-manutencao_interno {
    text-align: center;
    margin-top: 100px;
}

.container-manutencao_interno h1 {
    font-size: 24px;
    color: #333;
}

.container-manutencao_interno img {
    width: 50%;
    height: auto;
}

.logo {
    background-color: #2e5f41;
    display: flex;
    justify-content: center;
}

.container-manutencao__text {
    text-align: center;
    margin-bottom: 10px;
}

.container-manutencao__text h1{
    color: #2e5f41;
    font-weight: 600;
    font-size: 1.5rem;
    list-style: none;
    line-height: 1rem;
}
.container-manutencao__text p{
    color: #2e5f41;
    font-weight: 600;
    font-size: 1.5rem;
    list-style: none;
    line-height: 1rem;
}

.manutencao_img {
    width: 60vw;
    margin: 0 auto;
    height: 60vh;
}

@media (max-width: 768px) {
    .container-manutencao__text {
        padding: 5px 0px;

    }

    .manutencao_img {
        width: 98vw;
        height: 40vh;
    }
}