@import "../../globals/commons.scss";

#faq {
    margin-bottom: clamp(5.9375rem, 6.6964vw + 2.7232rem, 8.75rem);
}

.parceiros {
    width: 100%;
    max-width: var(--max-wrapper-width);
    margin-right: auto;
    margin-left: auto;
}

.parceiros-title {
    color: #105656;
}

.parceiros-logo-container {
    position: relative;
    display: flex;
    overflow: hidden;
    padding: 50px 0 90px;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    background-color: #fff;
}

.parceiros-group {
    display: flex;
    justify-content: center;
    flex-direction: row;
}

.parceiros-card {
    display: flex;
    width: 270px;
    min-width: 270px;
    margin-right: 10px;
    margin-left: 10px;
    margin-bottom: 20px;
    margin-top: 20px;
    padding: 30px 0 21px;
    justify-content: center;
    border-radius: 20px;
    background-color: #fff;
    box-shadow: 2px 2px 30px -18px #1f22245c;
    align-items: flex-end;
}
.parceiros-logo {
    width: 70%;
    height: 70px;
}
.parceiros-logo img {
    width: 100%;
    height: auto;
}

.faq-card {
    display: grid;
    gap: clamp(0.4375rem, 1.7812vw + 0.02rem, 0.875rem);
    grid-template-rows: auto 1fr;
}

.faq-card .faq-card__title,
.faq-card .faq-card__text {
    color: var(--lack-prime);
}

.faq-card .faq-card__title {
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 120%;
}

.faq-card .faq-card__text {
    font-family: var(--ff-accent);
    font-size: 0.875rem;
    letter-spacing: 0.14px;
    line-height: 150%;
}

.faq .faq__title {
    margin-bottom: clamp(1rem, 9.6692vw + -1.2662rem, 3.375rem);
    color: var(--blue-active);
    font-size: clamp(2.25rem, 2.2901vw + 1.7133rem, 2.8125rem);
    font-weight: 500;
    line-height: 110%;
}

.faq .faq__content {
    display: grid;
    gap: 1.875rem;
}

.faq .wrapper {
    padding-inline: clamp(2.25rem, 4.8346vw + 1.1169rem, 3.4375rem);
}

@media only screen and (max-width: 1226px) {
    .parceiros-card {
        width: 230px;
        min-width: 230px;
        margin-right: 5px;
        margin-left: 5px;
        padding: 20px 0 10px;
    }
}

@media only screen and (min-width: 1024px) {
    .wrapper {
        padding-inline: calc(var(--gutter) * 2);
    }

    .faq .faq__title {
        grid-column: 2 / span 10;
    }

    .faq .faq__content {
        grid-template-columns: repeat(4, minmax(0, 1fr));
        gap: 3.125rem;
        grid-column: 2 / span 10;
    }

    .faq .wrapper {
        display: grid;
        grid-template-columns: repeat(12, minmax(0, 1fr));
        gap: var(--gutter);
    }
}

@media only screen and (max-width: 768px) {
    .parceiros-group {
        flex-direction: column;
        align-items: center;
    }

    .parceiros-card {
        margin-top: 10px;
    }

    .faq .faq__title small {
        font-size: 1.25rem;
        letter-spacing: 0.01em;
    }

    .faq .faq__title small:before {
        content: "- ";
    }

    // .faq .faq__title small {
    //     display: block
    // }
}
