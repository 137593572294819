@import "../../globals/commons.scss";

.modal {
    --modal-zindex: 1055;
    --modal-width: 38.25rem;
    --modal-padding: 1rem;
    --modal-margin: 0.5rem;
    --modal-bg: var(--black-prime);
    --modal-border-color: var(--border-color-translucent);
    --modal-border-width: var(--border-width);
    --modal-border-radius: var(--border-radius);
    --modal-box-shadow: var(--box-shadow);
    --modal-color: var(--blue-light);
    position: fixed;
    top: 0;
    left: 0;
    z-index: var(--modal-zindex);
    display: none;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0
}

.modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px)
}

.modal.show .modal-dialog {
    transform: none
}

.modal-dialog {
    position: relative;
    width: auto;
    margin: var(--modal-margin);
    pointer-events: none;
    height: calc(100% - var(--modal-margin) * 2)
}

.modal-dialog-centered {
    display: flex;
    align-items: center;
    min-height: calc(100% - var(--modal-margin) * 2)
}

.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: 100%;
    overflow: hidden;
    outline: 0;
    border: var(--modal-border-width) solid var(--modal-border-color);
    pointer-events: auto;
    color: var(--modal-color);
    background-color: var(--modal-bg);
    background-clip: padding-box;
    border-radius: 0%;
}

.modal-header {
    display: flex;
    justify-content: flex-end;
    padding: 1rem 1rem 0.4375rem;
    border-bottom: 0;
}

.modal-title-wrap {
    margin-bottom: 2rem
}


.btn-close {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0.5rem;
    padding: 0;
    border: none;
    color: var(--gray-light);
    font-family: var(--ff-accent);
    font-size: 0.625rem;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    background: none;
    transition-property: color;
    transition-duration: 0.15s;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1)
}

.btn-close:hover {
    color: var(--blue-light);
}

.btn-close svg {
    flex: 0 0 auto;
    width: 0.8125rem;
    height: 0.8125rem
}

.btn-close svg path {
    fill: currentColor
}

.modal-title {
    color: var(--modal-color);
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1.35
}

.modal-body {
    position: relative;
    flex: 1 1 auto;
    overflow-y: auto;
    padding: 0 clamp(1rem, 6.1069vw + -0.4313rem, 2.5rem) 2.5rem
}

.modal-backdrop {
    --backdrop-zindex: 1050;
    --backdrop-bg: #000;
    --backdrop-opacity: 0.8;
    position: fixed;
    top: 0;
    left: 0;
    z-index: var(--backdrop-zindex);
    width: 100vw;
    height: 100vh;
    background-color: var(--backdrop-bg)
}

.modal-backdrop.fade {
    opacity: 0
}

.modal-backdrop.show {
    opacity: var(--backdrop-opacity)
}

.contact-modal {
    --field-border-color: var(--blue-light);
    --field-color: #b3b3b3
}

.field {
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 0.3125rem
}

.field-group {
    display: flex;
    flex-wrap: wrap;
    gap: 1.125rem 1rem
}

.field .field-label {
    color: var(--blue-light);
    font-family: var(--ff-accent);
    font-size: 0.75rem;
    font-weight: 400;
    letter-spacing: 0.01em
}

.field .field-control {
    min-height: 2.5rem;
    padding: 0.25rem 0.625rem;
    border: 1px solid #54c9ee;
    ;
    color: #b3b3b3;
    ;
    font-family: var(--ff-accent);
    font-size: 0.875rem;
    background: none
        /* &:focus
    &:not(:placeholder-shown)
      & ~ .field-label
        opacity .6
        transform scale(.8) translateY(rems(-30)) */
}

.field .field-control::placeholder {
    color: rgba(179, 179, 179, 0.7)
}

.field .field-control:is(select) {
    appearance: none
}

.field .field-control:is(textarea) {
    resize: none
}

.field .field-control[type='file'] {
    color: inherit
}

.field-textarea {
    display: flex;
    flex-direction: column;
    gap: 0.3125rem
}

.field-textarea .field-control {
    resize: none;
    height: 13.25rem;
    padding: 0.25rem 0.625rem;
    border: 1px solid var(--field-border-color);
    color: var(--field-color);
    font-family: var(--ff-accent);
    font-size: 0.875rem;
    background: none
}

.field-select {
    appearance: none;
    flex: 1;
    min-height: 1.625rem;
    width: 100%;
    padding: 0.25rem 1.75rem 0.25rem 0.125rem;
    border: none;
    border-bottom: 1px solid var(--gray-300);
    color: var(--gray-700);
    font-size: 0.875rem;
    background-color: transparent;
    // background-image: url("../fonts/angle-down-solid.svg");
    background-position: bottom 0.3125rem right 0.5rem;
    background-repeat: no-repeat;
    background-size: 0.75rem
}

.field-select:focus:invalid,
.field-select:not(:focus):invalid {
    font-size: 0.875rem
}


.field-select+.field-label {
    display: none
}

.field-check {
    display: flex;
    padding-left: 1.5rem;
    gap: 0.625rem;
    align-items: center
}

.field-check .field-check-input {
    appearance: none;
    flex: 0 0 auto;
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-left: -1.5rem;
    border: 1px solid var(--gray-heavy);
    // -webkit-print-color-adjust: exact;
    background-color: transparent;
    background-position: center;
    background-size: contain
}

.field-check .field-check-input[type=checkbox] {
    border-radius: 0
}

.field-check .field-check-input:checked[type=checkbox] {
    background-size: 100%;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23707070' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e")
}

.field-check .field-check-label {
    color: var(--gray-600);
    font-family: var(--ff-accent);
    font-size: 0.6875rem;
    letter-spacing: 0.025em
}

.field-check .field-check-label a {
    color: inherit;
    text-decoration: underline
}

.field-check .field-check-label a:hover {
    color: var(--blue-light)
}

.form {
    position: relative
}

.form .form__content {
    display: grid;
    gap: 1.125rem
}


.form .form-actions {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 0.9375rem 1.5rem;
    margin-top: 1.5rem
}

.form .form-actions .link {
    color: var(--white);
    font-family: var(--ff-accent);
    font-size: 0.625rem;
    letter-spacing: 0.03em;
    text-decoration: underline
}

.form .form-actions .btn.btn--submit {
    width: 100%;
    min-height: 3rem
}

.form.form--sending .btn.btn--submit {
    pointer-events: none
}

.form.form--sending .btn.btn--submit .btn__label {
    visibility: hidden
}

.form.form--sending .btn.btn--submit .btn__icon {
    display: inline-flex
}

.form.form--success .form__content {
    opacity: 0;
    pointer-events: none
}

.form.form--success .form-feedback {
    opacity: 1
}

.form.form--success .form-feedback .form-feedback__success {
    display: flex
}

.form.form--error .form__content {
    opacity: 0;
    pointer-events: none
}

.form.form--error .form-feedback {
    opacity: 1
}

.form.form--error .form-feedback .form-feedback__fail {
    display: flex
}

.form .field-group+.field-group {
    margin-top: 2.1875rem
}

.form-feedback {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    opacity: 0;
    text-align: center;
    transition: 0.35s ease
}

.form-feedback svg {
    width: 5.375rem;
    height: 5.375rem
}

.form-feedback .form-feedback__info {
    margin-inline: auto;
    font-family: var(--ff-accent);
    font-size: 0.875rem;
    line-height: 150%;
    text-align: center
}

.form-feedback .btn-again {
    border: none;
    padding: 0;
    background: none;
    color: var(--gray-medium);
    font-family: var(--ff-accent);
    font-size: 0.875rem;
    text-align: center;
    text-decoration: underline;
    transition: color 0.15s cubic-bezier(0.4, 0, 0.2, 1)
}

.form-feedback .btn-again:hover {
    color: var(--blue-light)
}

.form-feedback .form-feedback__success,
.form-feedback .form-feedback__fail {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.875rem
}

.form-feedback .form-feedback__success {
    display: none
}

.form-feedback .form-feedback__success .form-feedback__info {
    color: var(--green-light)
}

.form-feedback .form-feedback__fail {
    display: none
}

.form-feedback .form-feedback__fail .form-feedback__info {
    color: var(--fail)
}



@media only screen and (min-width: 768px) {
    .form .form-actions .btn.btn--submit {
        max-width: 9.75rem
    }
}

@media only screen and (max-width: 767.98px) {
    .field-group {
        flex-direction: column
    }

    .form .form-actions {
        flex-direction: column-reverse;
        align-items: flex-start
    }
}

@media only screen and (min-width: 576px) {
    .modal {
        --modal-margin: 1.75rem
    }

    .modal-dialog {
        max-width: var(--modal-width);
        margin-right: auto;
        margin-left: auto
    }
}