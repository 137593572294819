@import "../../globals/commons.scss";
.swiper {
    --beneficios-paginacao-bullet-horizontal-gap: 0;
    --beneficios-paginacao-bullet-border-radius: 0;
    --beneficios-paginacao-bottom: 0;
    --swiper-navigation-size: 1rem;
    --swiper-navigation-top-offset: 45%;
    --swiper-navigation-sides-offset: 0.3125rem;
}

#tipos-de-credito {
    margin-bottom: clamp(4.875rem, 6.6158vw + 3.3244rem, 6.5rem);
}

.beneficios-paginacao {
    position: relative;
    display: flex;
    border-top: none;
    border-bottom: 2px solid var(--black-prime);
}

.beneficios-paginacao-bullet {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    width: auto;
    height: auto;
    min-height: clamp(1.3125rem, 0.7634vw + 1.1336rem, 1.5rem);
    opacity: 1;
    color: var(--black-prime);
    font-size: clamp(0.875rem, 0.5089vw + 0.7557rem, 1rem);
    font-weight: 400;
    background-color: var(--white-prime);

    border-right: 2px solid var(--black-prime);
    // border-left: 2px solid var(--black-prime);
}

/* Adiciona a borda esquerda apenas nos dois primeiros elementos da paginação */
.beneficios-paginacao-bullet:first-child {
    border-left: 2px solid var(--black-prime);
}

.beneficios-paginacao-bullet-active {
    background-color: var(--blue-light);
}

.swiper-horizontal > .beneficios-paginacao-bullets,
.beneficios-paginacao-bullets.beneficios-paginacao-horizontal,
.beneficios-paginacao-custom,
.beneficios-paginacao-fraction {
    display: flex;
    justify-content: center;
}

.beneficios .carousel-item {
    width: 100%;
    height: 360px;
}

.beneficios .wrapper {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    gap: 1.875rem var(--gutter);
}

.beneficios .swiper {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
    flex: 1;
}

.beneficios .tb-block {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    // flex: 0 0 auto;
    background-color: var(--white-prime);
    border: 1px solid var(--black-prime);
    // transition-property: background-color, filter;
    // transition-duration: 0.15s;
    // transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1)
}

.beneficios .tb-header {
    position: relative;
    z-index: 0;
    display: flex;
    align-items: center;
    overflow: hidden;
    padding: 1.5625rem clamp(1.5rem, 12.7226vw + -1.4819rem, 4.625rem);
    border-bottom: 1px solid var(--black-prime);
    background-color: var(--gray-light);
    border: 1px solid var(--black-prime);
}

.beneficios .tb-header img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.beneficios .tb-title {
    color: var(--white-prime);
    font-size: clamp(1.5rem, 2.0356vw + 1.0229rem, 2rem);
    font-weight: 600;
    line-height: 120%;
    text-transform: uppercase;
}

.beneficios .beneficios-paginacao {
    border-width: 1px;
}

.beneficios .beneficios-paginacao-bullet {
    border-width: 1px;
}

.tb-card {
    padding: clamp(1.625rem, 3.5623vw + 0.7901rem, 2.5rem) clamp(1.875rem, 8.6514vw + -0.1527rem, 4rem)
        clamp(1.625rem, 3.5623vw + 0.7901rem, 2.5rem) clamp(1.5rem, 10.1781vw + -0.8855rem, 4rem);
    padding-left: clamp(4rem, 1.4881vw + 3.2857rem, 4.625rem);
}

.tb-card .tb-card__title {
    margin-bottom: clamp(0.375rem, 1.5267vw + 0.0172rem, 0.75rem);
    color: var(--black-prime);
    font-size: clamp(1.25rem, 2.0356vw + 0.7729rem, 1.75rem);
    font-weight: 600;
    letter-spacing: -0.01em;
    line-height: 120%;
}

.tb-card .tb-card__text {
    color: var(--black-prime);
    font-family: var(--ff-accent);
    font-size: clamp(0.875rem, 0.5089vw + 0.7557rem, 1rem);
    letter-spacing: 0.01em;
    line-height: 150%;
}

.carousel-control-prev,
.carousel-control-next {
    justify-content: space-around;
}

.carousel-control-prev-icon {
    background-image: url(../../assets/img/seta-esquerda.png);
    width: 1rem;
    height: 1rem;
}

.carousel-control-next-icon {
    background-image: url(../../assets//img/seta-direita.png);
    width: 1rem;
    height: 1rem;
}

.bloco-benefits:hover {
    z-index: 0;
    filter: drop-shadow(0 8px 16px rgba(31, 31, 31, 0.3));
    background-color: var(--gray-light);
}

.descarbonizacao {
    margin-bottom: clamp(4.0625rem, 20.1018vw + -0.6489rem, 9rem);
}

.descarbonizacao .wrapper {
    width: 100%;
    max-width: var(--max-wrapper-width);
    margin-right: auto;
    margin-left: auto;
    padding-inline: var(--gutter);
}

.descarbonizacao .dw-title {
    display: flex;
    align-items: center;
    gap: 2.375rem;
    margin-bottom: clamp(3.75rem, 6.25vw + 0.75rem, 6.375rem);
    font-size: clamp(1.5rem, 1.7857vw + 0.6429rem, 2.25rem);
    padding-inline: 7%;
    color: var(--green-heavy);
    // font-size: 1.5rem;
    font-weight: 500;
    letter-spacing: 0.01em;
    line-height: 110%;
    text-align: center;
    text-transform: uppercase;
    text-wrap: balance;
}

.descarbonizacao .dw-title:before,
.descarbonizacao .dw-title:after {
    content: "";
    display: flex;
    flex: 1;
    height: 2px;
    background-color: currentColor;
}

.descarbonizacao .dw-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
}

.descarbonizacao .dw-image-wrap {
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
}

.descarbonizacao .dw-image-wrap::-webkit-scrollbar {
    display: block;
}

.descarbonizacao {
    display: flex;
    overflow: visible;
}

.descarbonizacao .swiper-wrapper {
    display: inline-flex;
    width: auto;
}

.descarbonizacao .swiper-slide {
    width: auto;
}

.descarbonizacao img {
    width: 100%;
    height: auto;
}


.descarbonizacao .wrapper {
    display: flex;
    flex-direction: column;
}

@media only screen and (min-width: 768px) {
    .beneficios .tb-header {
        min-height: 166px;
    }

    .descarbonizacao {
        margin-bottom: clamp(9rem, 10.5655vw + 3.9286rem, 13.4375rem);
    }

    .descarbonizacao .dw-title {
        margin-bottom: clamp(3.75rem, 6.25vw + 0.75rem, 6.375rem);
        font-size: clamp(1.5rem, 1.7857vw + 0.6429rem, 2.25rem);
    }
}

@media only screen and (min-width: 1024px) {
    .wrapper {
        padding-inline: calc(var(--gutter) * 2);
    }

    .beneficios .tb-header {
        padding-inline: clamp(4rem, 1.4881vw + 3.2857rem, 4.625rem);
    }

    .descarbonizacao .dw-slider img {
        max-height: 25rem;
    }
}
@media only screen and (min-width: 1201px) and (max-width: 1439px) {
    .beneficios .carousel-item {
        height: 420px;
    }
}
@media only screen and (min-width: 901px) and (max-width: 1200px) {
    .beneficios .carousel-item {
        height: 570px;
    }
}

@media only screen and (min-width: 769px) and (max-width: 900px) {
    .beneficios .carousel-item {
        height: 680px;
    }
}

@media only screen and (min-width: 501px) and (max-width: 768px) {
    .beneficios .carousel-item {
        height: 400px;
    }
}
@media only screen and (min-width: 351px) and (max-width: 500px) {
    .beneficios .carousel-item {
        height: 465px;
    }
}

@media only screen and (min-width: 0px) and (max-width: 350px) {
    .beneficios .carousel-item {
        height: 550px;
    }
}

@media only screen and (max-width: 1279.98px) {
    .descarbonizacao .dw-wrap {
        margin-inline: -2rem;
    }

    .descarbonizacao .dw-image-wrap {
        padding-inline: 2rem;
    }
}

@media only screen and (max-width: 1023.98px) {
    .descarbonizacao .dw-wrap {
        margin-inline: -1rem;
    }

    .descarbonizacao .dw-image-wrap {
        padding-inline: 6%;
    }

    .beneficios .tb-header br {
        display: none;
    }
}

@media only screen and (max-width: 767.98px) {
    .descarbonizacao .dw-image-wrap {
        padding-inline: 21%;
    }
}
