@import "../../globals/commons.scss";

//**********************Header**************************
.container-header_safecarbon {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1020;
  padding-block: 1.875rem;
  transition: 0.3s ease;
}

.container-header_safecarbon.headerColor {
  padding-block: 0.9375rem;
  background-color: var(--green-heavy);
  box-shadow: 0 2px 3px 0 rgba(60, 64, 67, 0.3), 0 2px 4px 2px rgba(60, 64, 67, 0.15)
}

//**********************Logo**************************
.header-logo_safecarbon {
  width: clamp(4.4375rem, 1.5267vw + 4.0797rem, 4.8125rem);
  height: clamp(1.875rem, 0.5089vw + 1.7557rem, 2rem)
}

.header-logo_safecarbon svg {
  width: 100%;
  height: 100%
}

//**********************Menus**************************
.header-menus_safecarbon {
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  max-width: var(--max-wrapper-width);
  margin-right: auto;
  margin-left: auto;
  padding-inline: var(--gutter);
}

.header-menu {
  display: flex;
  gap: 2.25rem
}

.header-menu .menu-item {
  display: flex;
  align-items: center
}

.header-menu .menu-item a {
  color: var(--white-prime);
  font-size: 0.625rem;
  font-weight: 400;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  transition: color 0.15s cubic-bezier(0.4, 0, 0.2, 1)
}


.header-menu .menu-item a:hover {
  color: var(--blue-light);
}


//**********************Botões**************************
.container-header_safecarbon .btn-wa {
  width: 1.5rem;
  height: auto;
}

.container-header_safecarbon .btn-wa svg {
  width: 100%;
  height: 100%;
  fill: var(--white-prime);
}

//**********************Responsivos**************************
@media only screen and (max-width: 1023.98px) {
  .container-header_safecarbon .navbar {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    display: flex;
    align-items: flex-end;
    height: 100vh;
    padding: 0;
    pointer-events: none;
  }

  .header-menu {
    width: 100%;
    align-items: center;
    justify-content: center;
    gap: clamp(1rem, 2.0356vw + 0.5229rem, 1.5rem);
    height: 2.125rem;
    pointer-events: all;
    background-color: rgba(31, 31, 31, 0.8);
  }

  .container-header_safecarbon .btn.btn-contanto {
    display: none
  }
}


@media only screen and (min-width: 768px) {
  .container-header_safecarbon {
    padding-block: clamp(2rem, 0.5952vw + 1.7143rem, 2.25rem)
  }

  .header-logo_safecarbon {
    width: clamp(4.8125rem, 5.506vw + 2.1696rem, 7.125rem);
    height: clamp(2rem, 2.381vw + 0.8571rem, 3rem)
  }
}


@media only screen and (min-width: 1024px) {
  .header-menus_safecarbon {
    padding-inline: calc(var(--gutter) * 2)
  }

  .header-menu .menu-item a {
    font-size: 0.8125rem;
    font-weight: 500
  }

}


@media only screen and (max-width: 1023.98px) {
  .container-header_safecarbon .navbar {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    display: flex;
    align-items: flex-end;
    height: calc(100vh - var(--vh-offset, 0px));
    pointer-events: none
  }


  .container-header_safecarbon .menu {
    width: 100%;
    align-items: center;
    justify-content: center;
    gap: clamp(1rem, 2.0356vw + 0.5229rem, 1.5rem);
    height: 2.125rem;
    pointer-events: all;
    background-color: rgba(31, 31, 31, 0.8)
  }

  .container-header_safecarbon .btn.btn-contato {
    display: none
  }

}

  @media only screen and (min-width: 1440px) {
    .header-logo_safecarbon {
      margin-right: 2.625rem
    }

    .header-menus_safecarbon {
      padding-inline: clamp(2rem, 4.7917vw + -2.3125rem, 3.4375rem)
    }
  }