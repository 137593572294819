@use "sass:math";
@import "./variables.scss";

// Local para os estilos de classes globais comuns a diversas telas

.wrapper {
  width: 100%;
  max-width: var(--max-wrapper-width);
  margin-right: auto;
  margin-left: auto;
  padding-inline: var(--gutter);
  font-family: 16 'Exo 2', sans-serif;
}

.fade {
  transition: opacity 0.15s linear
}

.fade:not(.show) {
  opacity: 0
}

.spin {
  animation: spin 2000ms linear infinite
}

.pulse {
  animation: spin 1000ms steps(8) infinite
}

.swiper {
  --beneficios-paginacao-bullet-horizontal-gap: 0;
  --beneficios-paginacao-bullet-border-radius: 0;
  --beneficios-paginacao-bottom: 0;
  --swiper-navigation-size: 1rem;
  --swiper-navigation-top-offset: 45%;
  --swiper-navigation-sides-offset: 0.3125rem
}

.btn {
  --btn-bg: transparent;
  --btn-bg-hover: transparent;
  --btn-bg-active: transparent;
  --btn-border-color: transparent;
  --btn-border-color-hover: transparent;
  --btn-border-color-active: transparent;
  --btn-color: var(--black);
  --btn-color-hover: var(--black);
  --btn-color-active: var(--black);
  --btn-font-size: 0.875rem;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: clamp(0.75rem, 0vw + 0.75rem, 0.75rem) clamp(1rem, 0vw + 1rem, 1rem);
  border: 1px solid var(--btn-border-color);
  border-radius: 0;
  color: var(--btn-color);
  font-family: var(--ff-base);
  font-size: var(--btn-font-size);
  font-weight: 600;
  letter-spacing: 7%;
  line-height: 1.2;
  text-align: center;
  text-transform: uppercase;
  background-color: var(--btn-bg);
  transition-property: color, background-color, border-color, box-shadow;
  transition-duration: 0.15s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1)
}

.btn:hover {
  color: var(--btn-color-hover);
  border-color: var(--btn-border-color-hover);
  background-color: var(--btn-bg-hover);
  box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.45)
}

.btn:active {
  color: var(--btn-color-active);
  border-color: var(--btn-border-color-active);
  background-color: var(--btn-bg-active)
}

.btn.btn--primary {
  --btn-bg: var(--blue-light);
  --btn-bg-hover: var(--gray-medium);
  --btn-bg-active: var(--gray-medium);
  --btn-border-color: var(--black-prime);
  --btn-border-color-hover: var(--black-prime);
  --btn-border-color-active: var(--black-prime);
  --btn-color: var(--black-prime);
  --btn-color-hover: var(--black-prime);
  --btn-color-active: var(--black-prime)
}

.btn.btn-contato {
  --btn-bg: var(--blue-light);
  --btn-bg-hover: var(--gray-medium);
  --btn-bg-active: var(--gray-medium);
  --btn-border-color: var(--black-prime);
  --btn-border-color-hover: var(--black-prime);
  --btn-border-color-active: var(--black-prime);
  --btn-color: var(--black-prime);
  --btn-color-hover: var(--black-prime);
  --btn-color-active: var(--black-prime);
  min-height: 3rem;
  min-width: 9.75rem
}

.btn.btn--outline-light {
    --btn-bg: transparent;
    --btn-bg-hover: var(--gray-medium);
    --btn-bg-active: var(--gray-medium);
    --btn-border-color: var(--white-prime);
    --btn-border-color-hover: var(--black-prime);
    --btn-border-color-active: var(--black-prime);
    --btn-color: var(--white-prime);
    --btn-color-hover: var(--black-prime);
    --btn-color-active: var(--black-prime)
}

.btn.btn--submit {
    --btn-bg: var(--blue-light);
    --btn-bg-hover: var(--gray-medium);
    --btn-bg-active: var(--gray-medium);
    --btn-border-color: var(--black-prime);
    --btn-border-color-hover: var(--black-prime);
    --btn-border-color-active: var(--black-prime);
    --btn-color: var(--black-prime);
    --btn-color-hover: var(--black-prime);
    --btn-color-active: var(--black-prime);
    font-family: var(--ff-base);
    letter-spacing: 0.07em
}

.btn.btn--submit .btn__icon {
    position: absolute;
    display: none;
    width: 1.25rem;
    height: 1.25rem;
    opacity: 0.6
}

// .editor {
//     font-family: var(--ff-accent)
// }

// .field {
//     position: relative;
//     display: flex;
//     flex: 1;
//     flex-direction: column;
//     gap: 0.3125rem
// }

// .field-group {
//     display: flex;
//     flex-wrap: wrap;
//     gap: 1.125rem 1rem
// }

// .field .field-label {
//     color: var(--blue-light);
//     font-family: var(--ff-accent);
//     font-size: 0.75rem;
//     font-weight: 400;
//     letter-spacing: 0.01em
// }

// .field .field-control {
//     appearance: none;
//     flex: 1;
//     min-height: 2.5rem;
//     padding: 0.25rem 0.625rem;
//     border: 1px solid var(--field-border-color);
//     color: var(--field-color);
//     font-family: var(--ff-accent);
//     font-size: 0.875rem;
//     background: none
//         /* &:focus
//     &:not(:placeholder-shown)
//       & ~ .field-label
//         opacity .6
//         transform scale(.8) translateY(rems(-30)) */
// }

// .field .field-control::placeholder {
//     color: rgba(179, 179, 179, 0.7)
// }

// .field .field-control:is(select) {
//     appearance: none
// }

// .field .field-control:is(textarea) {
//     resize: none
// }

// .field .field-control[type='file'] {
//     color: inherit
// }

// .field-textarea {
//     display: flex;
//     flex-direction: column;
//     gap: 0.3125rem
// }

// .field-textarea .field-control {
//     resize: none;
//     height: 13.25rem;
//     padding: 0.25rem 0.625rem;
//     border: 1px solid var(--field-border-color);
//     color: var(--field-color);
//     font-family: var(--ff-accent);
//     font-size: 0.875rem;
//     background: none
// }

// .field-select {
//     appearance: none;
//     flex: 1;
//     min-height: 1.625rem;
//     width: 100%;
//     padding: 0.25rem 1.75rem 0.25rem 0.125rem;
//     border: none;
//     border-bottom: 1px solid var(--gray-300);
//     color: var(--gray-700);
//     font-size: 0.875rem;
//     background-color: transparent;
//     // background-image: url("../fonts/angle-down-solid.svg");
//     background-position: bottom 0.3125rem right 0.5rem;
//     background-repeat: no-repeat;
//     background-size: 0.75rem
// }

// .field-select:focus:invalid,
// .field-select:not(:focus):invalid {
//     font-size: 0.875rem
// }

// .field-select+.field-label {
//     display: none
// }

// .field-check {
//     display: flex;
//     padding-left: 1.5rem;
//     gap: 0.625rem;
//     align-items: center
// }

// .field-check .field-check-input {
//     appearance: none;
//     flex: 0 0 auto;
//     width: 1rem;
//     height: 1rem;
//     margin-top: 0;
//     margin-left: -1.5rem;
//     border: 1px solid var(--gray-heavy);
//     -webkit-print-color-adjust: exact;
//     background-color: transparent;
//     background-position: center;
//     background-size: contain
// }

// .field-check .field-check-input[type=checkbox] {
//     border-radius: 0
// }

// .field-check .field-check-input:checked[type=checkbox] {
//     background-size: 100%;
//     background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23707070' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e")
// }

// .field-check .field-check-label {
//     color: var(--gray-600);
//     font-family: var(--ff-accent);
//     font-size: 0.6875rem;
//     letter-spacing: 0.025em
// }

// .field-check .field-check-label a {
//     color: inherit;
//     text-decoration: underline
// }

// .field-check .field-check-label a:hover {
//     color: var(--blue-light)
// }

.was-validated .field.is-invalid .field-label {
  color: var(--form-invalid-color)
}

.was-validated .field.is-invalid .field-feedback {
  display: block
}

.was-validated .field-control:invalid,
.was-validated .field-select:invalid {
  color: var(--form-invalid-color);
  border-color: var(--form-invalid-color)
}

.was-validated .field-control:invalid~.field-label,
.was-validated .field-select:invalid~.field-label {
  color: var(--form-invalid-color)
}

.was-validated .field-control:invalid~.field-feedback,
.was-validated .field-select:invalid~.field-feedback {
  display: block
}

.was-validated .field-check-input:invalid {
  border-color: var(--form-invalid-color)
}

.link {
  --lnk-color: inherit;
  --lnk-color-hover: inherit;
  --lnk-font-size: 1.125rem;
  --lnk-font-weight: $font-weight-bold;
  --lnk-icon-color: var(--black);
  --lnk-icon-color-hover: var(--black);
  color: var(--lnk-color);
  font-size: var(--lnk-font-size);
  font-weight: var(--lnk-font-weight);
  text-decoration: underline;
  text-decoration-skip-ink: none;
  text-underline-offset: 2px;
  transition-property: color, text-decoration, text-decoration-style;
  transition-timing-function: ease-in-out;
  transition-duration: 0.25s
}

.link:hover {
  color: var(--lnk-color-hover);
  text-decoration: underline;
  text-decoration-style: dotted
}

.link:hover.link--icon svg {
  fill: var(--lnk-icon-color-hover)
}

.link.link--primary {
  --lnk-color: var(--primary-600);
  --lnk-color-hover: var(--primary);
  --lnk-icon-color: var(--primary-600);
  --lnk-icon-color-hover: var(--primary)
}

.link.link--icon {
  display: inline-flex;
  align-items: center;
  gap: 0.3125rem
}

.link.link--icon svg {
  fill: var(--lnk-icon-color);
  width: 1em;
  height: 1em;
  transition: fill 0.25s ease-in-out
}

// .title {
//     --title-color: var(--blue-active);
//     color: var(--title-color);
//     font-size: 0.75rem;
//     font-weight: 600;
//     letter-spacing: 0.3em;
//     text-transform: uppercase;
//     vertical-align: baseline
// }

// .title:after {
//     content: '';
//     display: inline-flex;
//     width: 1rem;
//     height: 0.125rem;
//     background-color: currentColor
// }

// .contact-modal {
//     --field-border-color: var(--blue-light);
//     --field-color: #b3b3b3
// }

// .faq-card {
//     display: grid;
//     gap: clamp(0.4375rem, 1.7812vw + 0.02rem, 0.875rem);
//     grid-template-rows: auto 1fr
// }

// .faq-card .faq-card__title,
// .faq-card .faq-card__text {
//     color: var(--lack-prime)
// }

// .faq-card .faq-card__title {
//     font-size: 1.25rem;
//     font-weight: 700;
//     line-height: 120%
// }

// .faq-card .faq-card__text {
//     font-family: var(--ff-accent);
//     font-size: 0.875rem;
//     letter-spacing: 0.14px;
//     line-height: 150%
// }

// .form {
//   position: relative
// }

// .form-feedback {
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
//   width: 100%;
//   opacity: 0;
//   text-align: center;
//   transition: 0.35s ease
// }

// .form-feedback svg {
//   width: 5.375rem;
//   height: 5.375rem
// }

// .form-feedback .form-feedback__info {
//   margin-inline: auto;
//   font-family: var(--ff-accent);
//   font-size: 0.875rem;
//   line-height: 150%;
//   text-align: center
// }

// .form-feedback .btn-again {
//   border: none;
//   padding: 0;
//   background: none;
//   color: var(--gray-medium);
//   font-family: var(--ff-accent);
//   font-size: 0.875rem;
//   text-align: center;
//   text-decoration: underline;
//   transition: color 0.15s cubic-bezier(0.4, 0, 0.2, 1)
// }

// .form-feedback .btn-again:hover {
//   color: var(--blue-light)
// }

// .form-feedback .form-feedback__success,
// .form-feedback .form-feedback__fail {
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   gap: 0.875rem
// }

// .form-feedback .form-feedback__success {
//   display: none
// }

// .form-feedback .form-feedback__success .form-feedback__info {
//   color: var(--green-light)
// }

// .form-feedback .form-feedback__fail {
//   display: none
// }

// .form-feedback .form-feedback__fail .form-feedback__info {
//   color: var(--fail)
// }

// .form .form__content {
//   display: grid;
//   gap: 1.125rem
// }

// .form .form-actions {
//   display: flex;
//   justify-content: space-between;
//   align-items: flex-end;
//   gap: 0.9375rem 1.5rem;
//   margin-top: 1.5rem
// }

// .form .form-actions .link {
//   color: var(--white);
//   font-family: var(--ff-accent);
//   font-size: 0.625rem;
//   letter-spacing: 0.03em;
//   text-decoration: underline
// }

// .form .form-actions .btn.btn--submit {
//   width: 100%;
//   min-height: 3rem
// }

// .form.form--sending .btn.btn--submit {
//   pointer-events: none
// }

// .form.form--sending .btn.btn--submit .btn__label {
//     visibility: hidden
// }

// .form.form--sending .btn.btn--submit .btn__icon {
//   display: inline-flex
// }

// .form.form--success .form__content {
//   opacity: 0;
//   pointer-events: none
// }

// .form.form--success .form-feedback {
//   opacity: 1
// }

// .form.form--success .form-feedback .form-feedback__success {
//   display: flex
// }

// .form.form--error .form__content {
//   opacity: 0;
//   pointer-events: none
// }

// .form.form--error .form-feedback {
//   opacity: 1
// }

// .form.form--error .form-feedback .form-feedback__fail {
//   display: flex
// }

// .form .field-group+.field-group {
//   margin-top: 2.1875rem
// }


// .modal {
//     --modal-zindex: 1055;
//     --modal-width: 38.25rem;
//     --modal-padding: 1rem;
//     --modal-margin: 0.5rem;
//     --modal-bg: var(--black-prime);
//     --modal-border-color: var(--border-color-translucent);
//     --modal-border-width: var(--border-width);
//     --modal-border-radius: var(--border-radius);
//     --modal-box-shadow: var(--box-shadow);
//     --modal-color: var(--blue-light);
//     position: fixed;
//     top: 0;
//     left: 0;
//     z-index: var(--modal-zindex);
//     display: none;
//     width: 100%;
//     height: 100%;
//     overflow-x: hidden;
//     overflow-y: auto;
//     outline: 0
// }

// .modal.fade .modal-dialog {
//     transition: transform 0.3s ease-out;
//     transform: translate(0, -50px)
// }

// .modal.show .modal-dialog {
//     transform: none
// }

// .modal-dialog {
//     position: relative;
//     width: auto;
//     margin: var(--modal-margin);
//     pointer-events: none;
//     height: calc(100% - var(--modal-margin) * 2)
// }

// .modal-dialog-centered {
//     display: flex;
//     align-items: center;
//     min-height: calc(100% - var(--modal-margin) * 2)
// }

// .modal-content {
//     position: relative;
//     display: flex;
//     flex-direction: column;
//     width: 100%;
//     max-height: 100%;
//     overflow: hidden;
//     outline: 0;
//     border: var(--modal-border-width) solid var(--modal-border-color);
//     pointer-events: auto;
//     color: var(--modal-color);
//     background-color: var(--modal-bg);
//     background-clip: padding-box
// }

// .modal-header {
//     display: flex;
//     justify-content: flex-end;
//     padding: 1rem 1rem 0.4375rem
// }

// .modal-title-wrap {
//     margin-bottom: 2rem
// }

// .btn-close {
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     gap: 0.5rem;
//     padding: 0;
//     border: none;
//     color: var(--gray-light);
//     font-family: var(--ff-accent);
//     font-size: 0.625rem;
//     letter-spacing: 0.05em;
//     text-transform: uppercase;
//     background: none;
//     transition-property: color;
//     transition-duration: 0.15s;
//     transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1)
// }

// .btn-close:hover {
//     color: var(--blue-light)
// }

// .btn-close svg {
//     flex: 0 0 auto;
//     width: 0.8125rem;
//     height: 0.8125rem
// }

// .btn-close svg path {
//     fill: currentColor
// }

// .modal-title {
//     color: var(--modal-color);
//     font-size: 1.5rem;
//     font-weight: 600;
//     line-height: 1.35
// }

// .modal-body {
//     position: relative;
//     flex: 1 1 auto;
//     overflow-y: auto;
//     padding: 0 clamp(1rem, 6.1069vw + -0.4313rem, 2.5rem) 2.5rem
// }

// .modal-backdrop {
//     --backdrop-zindex: 1050;
//     --backdrop-bg: #000;
//     --backdrop-opacity: 0.8;
//     position: fixed;
//     top: 0;
//     left: 0;
//     z-index: var(--backdrop-zindex);
//     width: 100vw;
//     height: 100vh;
//     background-color: var(--backdrop-bg)
// }

// .modal-backdrop.fade {
//     opacity: 0
// }

// .modal-backdrop.show {
//     opacity: var(--backdrop-opacity)
// }

// .service-card {
//     background: none
// }

// .service-card .service-card__media {
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     border-bottom: 2px solid var(--black-prime)
// }

// .service-card .service-card__media img {
//     width: 100%;
//     height: auto;
//     object-fit: cover
// }

// .service-card .service-card__content {
//     padding: clamp(1rem, 7.1247vw + -0.6698rem, 2.75rem) clamp(1.5rem, 14.2494vw + -1.8397rem, 5rem)
// }

// .service-card .service-card__title {
//     margin-bottom: clamp(1rem, 4.0712vw + 0.0458rem, 2rem);
//     color: var(--blue-active);
//     font-size: clamp(1.5rem, 2.0356vw + 1.0229rem, 2rem);
//     font-weight: 600;
//     line-height: 110%;
//     text-transform: uppercase;
//     text-wrap: balance
// }

// .service-card .service-card__subtitle {
//     margin-bottom: clamp(0.875rem, 2.5445vw + 0.2786rem, 1.5rem);
//     color: var(--black-prime);
//     font-family: var(--ff-accent);
//     font-size: clamp(1rem, 2.0356vw + 0.5229rem, 1.5rem);
//     font-weight: 600;
//     line-height: 130%
// }

// .service-card .service-card__text {
//     font-family: var(--ff-accent);
//     font-size: clamp(0.875rem, 0.5089vw + 0.7557rem, 1rem);
//     letter-spacing: 0.01em;
//     line-height: 150%
// }

// .service-card .btn {
//     margin-top: clamp(1.625rem, 14.2494vw + -1.7147rem, 5.125rem);
//     padding: clamp(0.75rem, 0.7634vw + 0.5711rem, 0.9375rem) 2.25rem
// }

// .beneficios .carousel-item {
//     width: 100%;
//     height: 360px;
// }

// .tb-card {
//     padding: clamp(1.625rem, 3.5623vw + 0.7901rem, 2.5rem) clamp(1.875rem, 8.6514vw + -0.1527rem, 4rem) clamp(1.625rem, 3.5623vw + 0.7901rem, 2.5rem) clamp(1.5rem, 10.1781vw + -0.8855rem, 4rem);
//     padding-left: clamp(4rem, 1.4881vw + 3.2857rem, 4.625rem);
// }

// .tb-card .tb-card__title {
//     margin-bottom: clamp(0.375rem, 1.5267vw + 0.0172rem, 0.75rem);
//     color: var(--black-prime);
//     font-size: clamp(1.25rem, 2.0356vw + 0.7729rem, 1.75rem);
//     font-weight: 600;
//     letter-spacing: -0.01em;
//     line-height: 120%
// }

// .tb-card .tb-card__text {
//     color: var(--black-prime);
//     font-family: var(--ff-accent);
//     font-size: clamp(0.875rem, 0.5089vw + 0.7557rem, 1rem);
//     letter-spacing: 0.01em;
//     line-height: 150%
// }


// .decarbonization-wake {
//     margin-bottom: clamp(4.0625rem, 20.1018vw + -0.6489rem, 9rem)
// }

// .decarbonization-wake .dw-title {
//     display: flex;
//     align-items: center;
//     gap: 2.375rem;
//     margin-bottom: clamp(5rem, 5.598vw + 3.688rem, 6.375rem);
//     padding-inline: 7%;
//     color: var(--green-heavy);
//     font-size: 1.5rem;
//     font-weight: 500;
//     letter-spacing: 0.01em;
//     line-height: 110%;
//     text-align: center;
//     text-transform: uppercase;
//     text-wrap: balance
// }

// .decarbonization-wake .dw-title:before,
// .decarbonization-wake .dw-title:after {
//     content: '';
//     display: flex;
//     flex: 1;
//     height: 2px;
//     background-color: currentColor
// }

// .decarbonization-wake .dw-wrap {
//     display: flex;
//     align-items: center;
//     justify-content: center
// }

// .decarbonization-wake .dw-image-wrap {
//     overflow-x: scroll;
//     -webkit-overflow-scrolling: touch
// }

// .decarbonization-wake .dw-image-wrap::-webkit-scrollbar {
//     display: block
// }

// .decarbonization-wake .dw-image-wrap img {
//     width: auto;
//     height: 25rem
// }

// .decarbonization-wake .dw-slider {
//     display: flex;
//     overflow: visible
// }

.main-header .wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between
}

// .decarbonization-wake .dw-slider .swiper-wrapper {
//     display: inline-flex;
//     width: auto
// }

// .decarbonization-wake .dw-slider .swiper-slide {
//     width: auto
// }

// .decarbonization-wake .dw-slider img {
//     width: auto;
//     max-height: 22.5rem
// }

// .faq .faq__title {
//     margin-bottom: clamp(1rem, 9.6692vw + -1.2662rem, 3.375rem);
//     color: var(--blue-active);
//     font-size: clamp(2.25rem, 2.2901vw + 1.7133rem, 2.8125rem);
//     font-weight: 500;
//     line-height: 110%
// }

// .faq .faq__title small {
//     font-size: 1.25rem;
//     letter-spacing: 0.01em
// }

// .faq .faq__content {
//     display: grid;
//     gap: 1.875rem
// }

// .faq .wrapper {
//     padding-inline: clamp(2.25rem, 4.8346vw + 1.1169rem, 3.4375rem)
// }

// .main-hero {
//     position: relative;
//     z-index: 0;
//     height: calc(100vh - var(--vh-offset, 0px))
// }

// .main-hero .main-hero__media {
//     position: absolute;
//     inset: 0;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     z-index: 0;
//     overflow: hidden
// }

// .main-hero .main-hero__media img {
//     width: 100%;
//     height: 100%;
//     object-fit: cover;
//     object-position: top center
// }

// .main-hero .main-hero__content {
//     position: relative;
//     z-index: 1;
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     height: 100%;
//     margin-bottom: 1.5625rem;
//     padding-inline: clamp(2.25rem, 19.3384vw + -2.2824rem, 7rem)
// }

// .main-hero .main-hero__content h1,
// .main-hero .main-hero__content h2 {
//     color: var(--white-prime);
//     text-transform: uppercase;
//     line-height: 1.2
// }

// .main-hero .main-hero__content h1 {
//     margin-bottom: clamp(-0.375rem, 4.0712vw + -1.3292rem, 0.625rem);
//     font-weight: 600;
//     font-size: clamp(4.75rem, 3.0534vw + 4.0344rem, 5.5rem);
//     line-height: 0.9
// }

// .main-hero .main-hero__content h2 {
//     font-weight: 300;
//     font-size: clamp(1.9594rem, 7.7226vw + 0.1494rem, 3.8563rem)
// }

// .main-hero .main-hero__content .btn {
//     margin-top: clamp(1.875rem, 2.5445vw + 1.2786rem, 2.5rem)
// }

// .our-mission {
//     position: relative;
//     z-index: 0;
//     grid-row: 2;
//     padding-block: clamp(2.8125rem, 37.4046vw + -5.9542rem, 12rem);
//     background-color: var(--green-heavy)
// }

// .our-mission .wrap {
//     display: grid
// }

// .our-mission .title-accent {
//     margin-bottom: clamp(1.125rem, 1.5267vw + 0.7672rem, 1.5rem);
//     color: var(--white-prime);
//     font-size: clamp(1.5rem, 2.0356vw + 1.0229rem, 2rem);
//     font-weight: 500;
//     text-transform: uppercase
// }

// .our-mission .title-accent span {
//     color: var(--blue-light)
// }

// .our-mission .editor {
//     font-family: var(--ff-base)
// }

// .our-mission .editor p {
//     color: var(--white-prime);
//     font-size: 1.25rem;
//     line-height: 1.6;
//     letter-spacing: -0.04em;
//     text-wrap: pretty
// }


// .services {
//     margin-bottom: 5.9375rem;
//     padding-block: 3rem;
//     background-size: cover;
//     background-image: url(../../assets/img/servicos-bg.jpg);
// }

// .services .swiper {
//     --swiper-navigation-top-offset: 58%
// }

// .services .title {
//     --title-color: var(--white-prime);
//     margin-bottom: 1.25rem
// }

// .services .editor {
//     color: var(--white-prime);
//     font-family: var(--ff-base);
//     font-size: clamp(1.5rem, 1.0178vw + 1.2615rem, 1.75rem);
//     font-weight: 600;
//     line-height: 130%;
//     text-transform: uppercase
// }

// .services .editor p {
//     text-wrap: balance
// }

// .services .services__slider {
//     border: 2px solid var(--black-prime);
//     background-color: var(--white-prime);
//     transition-property: background-color filter;
//     transition-duration: 0.15s;
//     transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1)
// }

// .services .services__slider:hover {
//     filter: drop-shadow(0 16px 36px rgba(0, 0, 0, 0.44));
//     background-color: var(--gray-light)
// }

// .beneficios {
//     margin-bottom: clamp(4.875rem, 6.6158vw + 3.3244rem, 6.5rem)
// }

// .carousel-control-prev,
// .carousel-control-next {
//     justify-content: space-around;
// }

// .carousel-control-prev-icon {
//     background-image: url(../../assets/img/seta-esquerda.png);
//     width: 1rem;
//     height: 1rem;
// }


// .carousel-control-next-icon {
//     background-image: url(../../assets//img/seta-direita.png);
//     width: 1rem;
//     height: 1rem;
// }


// .beneficios .wrapper {
//     display: grid;
//     grid-template-columns: minmax(0, 1fr);
//     gap: 1.875rem var(--gutter)
// }

// .beneficios .swiper {
//     display: flex;
//     flex-direction: column;
//     width: 100%;
//     justify-content: space-between;
//     flex: 1
// }

// .beneficios .tb-block {
//     position: relative;
//     z-index: 1;
//     display: flex;
//     flex-direction: column;
//     // flex: 0 0 auto;
//     background-color: var(--white-prime);
//     border: 1px solid var(--black-prime);
//     // transition-property: background-color, filter;
//     // transition-duration: 0.15s;
//     // transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1)
// }

// .beneficios .tb-header {
//     position: relative;
//     z-index: 0;
//     display: flex;
//     align-items: center;
//     overflow: hidden;
//     padding: 1.5625rem clamp(1.5rem, 12.7226vw + -1.4819rem, 4.625rem);
//     border-bottom: 1px solid var(--black-prime);
//     background-color: var(--gray-light);
//     border: 1px solid var(--black-prime);
// }

// .beneficios .tb-header img {
//     position: absolute;
//     top: 0;
//     left: 0;
//     z-index: -1;
//     width: 100%;
//     height: 100%;
//     object-fit: cover
// }

// .bloco-benefits:hover {
//     z-index: 0;
//     filter: drop-shadow(0 8px 16px rgba(31, 31, 31, 0.3));
//     background-color: var(--gray-light);
// }

// .beneficios .tb-title {
//     color: var(--white-prime);
//     font-size: clamp(1.5rem, 2.0356vw + 1.0229rem, 2rem);
//     font-weight: 600;
//     line-height: 120%;
//     text-transform: uppercase;
// }

// .beneficios .beneficios-paginacao {
//     border-width: 1px;
// }

// .beneficios .beneficios-paginacao-bullet {
//     border-width: 1px
// }



.page-template-minuano {
  --color-primary: #234051
}

.page-template-salva {
  --color-primary: #4e503e
}

.page-template-salva .section-header .section-header__title {
  width: clamp(3.75rem, 48.3461vw + -7.5811rem, 15.625rem);
  margin-inline: auto;
  text-align: center
}

.section-header {
  margin-block: clamp(1.5rem, 6.6158vw + -0.0506rem, 3.125rem) clamp(0.75rem, 3.0534vw + 0.0344rem, 1.5rem)
}

.section-header .section-header__title {
  width: clamp(6.125rem, 72.2646vw + -10.812rem, 23.875rem);
  margin-inline: auto;
  text-align: center
}

.section-header .section-header__title img {
  width: 100%;
  height: auto
}

.future-design {
  margin-block: clamp(0.75rem, 3.0534vw + 0.0344rem, 1.5rem) clamp(1.5rem, 8.6514vw + -0.5277rem, 3.625rem)
}

.future-design:after {
  content: '';
  display: block;
  width: clamp(10rem, 80.6616vw + -8.9051rem, 29.8125rem);
  height: 1px;
  margin-inline: auto;
  background-color: var(--gray-700)
}

.future-design .title {
  margin-bottom: clamp(0.875rem, 5.3435vw + -0.3774rem, 2.1875rem);
  color: var(--color-primary);
  font-weight: 700;
  line-height: 0.974025974025974;
  text-align: center;
  text-wrap: balance
}

.future-design .title em {
  font-style: italic
}

.future-design .editor {
  margin-bottom: clamp(1.25rem, 9.1603vw + -0.8969rem, 3.5rem);
  font-size: clamp(0.8125rem, 3.5623vw + -0.0224rem, 1.6875rem);
  line-height: 1.169811320754717;
  text-align: center;
  text-wrap: balance
}

.future-design .future-design__embed-video {
  position: relative;
  margin-right: calc(0px - clamp(1.25rem, 13.9949vw + -2.0301rem, 4.6875rem));
  margin-bottom: clamp(0.75rem, 9.1603vw + -1.3969rem, 3rem);
  margin-left: calc(0px - clamp(1.25rem, 13.9949vw + -2.0301rem, 4.6875rem));
  background-color: var(--black);
  aspect-ratio: 16/9
}

.future-design .future-design__embed-video.reveal-video .yt-preview,
.future-design .future-design__embed-video.reveal-video .yt-btn-player {
  display: none
}

.future-design .yt-preview {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden
}

.future-design .yt-preview iframe {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  height: 200%
}

.future-design .yt-player {
  display: flex;
  width: 100%;
  height: 100%
}

.future-design .yt-player iframe {
  width: 100%;
  height: 100%
}

.future-design .yt-btn-player {
  position: absolute;
  inset: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  border: 0 none;
  background-color: rgba(0, 0, 0, 0);
  transition: 0.15s cubic-bezier(0.4, 0, 0.2, 1)
}

.future-design .yt-btn-player:hover {
  opacity: 0.95;
  background-color: rgba(0, 0, 0, 0.15)
}

.future-design .yt-btn-player svg {
  width: clamp(4.375rem, 6.6158vw + 2.8244rem, 6rem);
  height: auto
}

.future-design .yt-btn-player svg path:first-child {
  fill: var(--color-primary)
}


.certifications {
  margin-bottom: clamp(2.5rem, 9.6692vw + 0.2338rem, 4.875rem)
}

.certifications .wrapper {
  display: grid;
  padding-inline: clamp(3.25rem, 2.0356vw + 2.7729rem, 3.75rem)
}

.certifications .certifications__header {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: clamp(0.3125rem, 4.8346vw + -0.8206rem, 1.5rem) clamp(0.625rem, 12.7226vw + -2.3569rem, 3.75rem);
  margin-inline: clamp(1.25rem, 5.0891vw + 0.0573rem, 2.5rem);
  border: 1px solid var(--black);
  border-radius: clamp(0.4375rem, 6.3613vw + -1.0534rem, 2rem) clamp(0.4375rem, 6.3613vw + -1.0534rem, 2rem) 0 0;
  border-bottom: none
}

.certifications .certifications__header .title {
  font-weight: 400;
  font-size: clamp(0.9375rem, 4.3257vw + -0.0763rem, 2rem);
  line-height: 1;
  text-align: center;
  text-wrap: balance
}

.certifications .certifications__content {
  padding: clamp(2.5rem, 3.8168vw + 1.6054rem, 3.4375rem) clamp(2.1875rem, 5.0891vw + 0.9948rem, 3.4375rem);
  border: 1px solid var(--black);
  border-radius: clamp(1.0625rem, 3.8168vw + 0.1679rem, 2rem)
}

.certifications .certifications__items {
  display: grid;
  gap: clamp(1.5rem, 6.6158vw + -0.0506rem, 3.125rem)
}

.where-find .title {
  margin-bottom: 0.442477876106195em;
  color: var(--color-primary);
  font-size: clamp(1.8125rem, 6.8702vw + 0.2023rem, 3.5rem);
  font-weight: 500;
  text-align: center;
  text-wrap: balance;
  line-height: 1
}

.where-find .title strong {
  font-weight: 700
}

.where-find .headline {
  margin-bottom: clamp(1.125rem, 1.5267vw + 0.7672rem, 1.5rem);
  font-family: var(--ff-accent);
  font-size: clamp(0.8125rem, 3.5623vw + -0.0224rem, 1.6875rem);
  font-weight: 400;
  line-height: 1.169811320754717;
  text-align: center;
  text-wrap: balance
}

.where-find .btn-wrap {
  position: relative;
  z-index: 10;
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: calc(0px - clamp(0.625rem, 2.5445vw + 0.0286rem, 1.25rem))
}

// @media only screen and (min-width: 576px) {
//     .modal {
//         --modal-margin: 1.75rem
//     }

//     .modal-dialog {
//         max-width: var(--modal-width);
//         margin-right: auto;
//         margin-left: auto
//     }
// }

@media only screen and (min-width: 768px) {
  // .form .form-actions .btn.btn--submit {
  //   max-width: 9.75rem
  // }

  // .service-card .service-card__title {
  //     line-height: 120%
  // }



  // .decarbonization-wake {
  //     margin-bottom: clamp(9rem, 10.5655vw + 3.9286rem, 13.4375rem)
  // }

  // .decarbonization-wake .dw-title {
  //     margin-bottom: clamp(3.75rem, 6.25vw + 0.75rem, 6.375rem);
  //     font-size: clamp(1.5rem, 1.7857vw + 0.6429rem, 2.25rem)
  // }

  // .faq .faq__title small {
  //     font-size: clamp(1.25rem, 1.5625vw + 0.5rem, 1.9063rem)
  // }

  // .faq .faq__title small:before {
  //     content: '- '
  // }


  // .services {
  //     margin-bottom: clamp(5.9375rem, 6.6964vw + 2.7232rem, 8.75rem)
  // }

  // .services .title {
  //     margin-bottom: clamp(1.25rem, 1.4881vw + 0.5357rem, 1.875rem)
  // }

  // .services .editor {
  //     font-size: clamp(1.75rem, 1.7857vw + 0.8929rem, 2.5rem)
  // }

  // .beneficios {
  //     margin-bottom: clamp(6.5rem, 8.3333vw + 2.5rem, 10rem)
  // }

  // .beneficios .tb-header {
  //     min-height: 9.8125rem
  // }

}

// @media only screen and (max-width: 768px) {
//     .beneficios .carousel-item {
//         height: 500px;
//     }
// }


@media only screen and (min-width: 1024px) {
  .wrapper {
    padding-inline: calc(var(--gutter) * 2)
  }


  // .primary-menu {
  //   gap: 1.875rem
  // }

  // .primary-menu>.menu-item:after {
  //   bottom: 0;
  //   left: 50%
  // }

  // .primary-menu>.menu-item a {
  //   font-size: 0.875rem
  // }

  .swiper {
    --swiper-navigation-sides-offset: 1.875rem
  }

  .title {
    font-size: clamp(0.75rem, 0.5952vw + 0.4643rem, 1rem)
  }


  // .decarbonization-wake .dw-slider img {
  //     max-height: 25rem
  // }

  // .faq .faq__title {
  //     grid-column: 2/span 10
  // }

  // .faq .faq__content {
  //     grid-template-columns: repeat(4, minmax(0, 1fr));
  //     gap: 3.125rem;
  //     grid-column: 2/span 10
  // }

  // .faq .wrapper {
  //     display: grid;
  //     grid-template-columns: repeat(12, minmax(0, 1fr));
  //     gap: var(--gutter)
  // }


  // .services .wrapper {
  //     display: grid;
  //     grid-template-columns: repeat(12, minmax(0, 1fr));
  //     gap: var(--gutter)
  // }

  // .services .col-text {
  //     grid-column: 2/span 4;
  //     padding-top: 6.625rem;
  //     margin-right: -1.25rem
  // }

  // .services .col-slider {
  //     grid-column: 7/span 6
  // }


  // .beneficios .tb-header {
  //     padding-inline: clamp(4rem, 1.4881vw + 3.2857rem, 4.625rem)
  // }

  .certifications .certifications__items {
    grid-template-columns: repeat(4, minmax(0, 1fr))
  }
}


@media only screen and (min-width: 1440px) {

  .wrapper {
    padding-inline: clamp(2rem, 4.7917vw + -2.3125rem, 3.4375rem);
  }


  // .beneficios .tb-header {
  //     padding-inline: clamp(4.625rem, 4.5833vw + 0.5rem, 6rem);
  //     padding-block: clamp(1.5625rem, 4.7917vw + -2.75rem, 3rem)
  // }


  .page-template-salva .section-header .section-header__title {
    width: clamp(15.625rem, 52.0833vw + -31.25rem, 31.25rem)
  }

  .section-header {
    margin-block: clamp(3.125rem, 10.4167vw + -6.25rem, 6.25rem)
  }

  .section-header .section-header__title {
    width: clamp(23.875rem, 79.5833vw + -47.75rem, 47.75rem)
  }

  .future-design {
    margin-block: clamp(3.625rem, 11.875vw + -7.0625rem, 7.1875rem)
  }

  .future-design:after {
    width: clamp(29.8125rem, 99.375vw + -59.625rem, 59.625rem);
    height: 2px
  }

  .future-design .title {
    margin-bottom: clamp(2.1875rem, 7.2917vw + -4.375rem, 4.375rem);
    font-size: clamp(4.8125rem, 16.0417vw + -9.625rem, 9.625rem)
  }

  .future-design .editor {
    font-size: clamp(1.6875rem, 5.4167vw + -3.1875rem, 3.3125rem);
    margin-bottom: clamp(3.5rem, 11.6667vw + -7rem, 7rem)
  }

  .future-design .future-design__embed-video {
    margin-right: calc(0px - clamp(4.6875rem, 15.625vw + -9.375rem, 9.375rem));
    margin-bottom: clamp(3rem, 10.4167vw + -6.375rem, 6.125rem);
    margin-left: calc(0px - clamp(4.6875rem, 15.625vw + -9.375rem, 9.375rem))
  }

  .future-design .yt-btn-player svg {
    width: clamp(6rem, 17.5vw + -9.75rem, 11.25rem)
  }

  .certifications {
    margin-bottom: clamp(4.875rem, 16.25vw + -9.75rem, 9.75rem)
  }

  .certifications .wrapper {
    padding-inline: clamp(3.75rem, 12.5vw + -7.5rem, 7.5rem)
  }

  .certifications .certifications__header {
    padding: clamp(1.5rem, 2.2917vw + -0.5625rem, 2.1875rem) clamp(3.75rem, 6.25vw + -1.875rem, 5.625rem);
    margin-inline: auto;
    border-width: 2px;
    border-radius: clamp(2rem, 6.4583vw + -3.8125rem, 3.9375rem) clamp(2rem, 6.4583vw + -3.8125rem, 3.9375rem) 0 0
  }

  .certifications .certifications__header .title {
    font-size: clamp(2rem, 6.4583vw + -3.8125rem, 3.9375rem)
  }

  .certifications .certifications__content {
    padding: clamp(3.4375rem, 11.4583vw + -6.875rem, 6.875rem) clamp(3.4375rem, 11.4583vw + -6.875rem, 6.875rem) clamp(2.375rem, 7.5vw + -4.375rem, 4.625rem);
    border-width: 2px;
    border-radius: clamp(2rem, 6.4583vw + -3.8125rem, 3.9375rem)
  }

  .where-find .wrapper {
    padding-inline: clamp(8.75rem, 29.1667vw + -17.5rem, 17.5rem)
  }

  .where-find .title {
    font-size: clamp(3.5rem, 11.875vw + -7.1875rem, 7.0625rem)
  }

  .where-find .headline {
    margin-bottom: clamp(1.5rem, 5vw + -3rem, 3rem);
    font-size: clamp(1.6875rem, 5.4167vw + -3.1875rem, 3.3125rem)
  }

  .where-find .swiper-horizontal>.beneficios-paginacao-bullets,
  .where-find .beneficios-paginacao-bullets.beneficios-paginacao-horizontal,
  .where-find .beneficios-paginacao-custom,
  .where-find .beneficios-paginacao-fraction {
    bottom: clamp(3.125rem, 9.375vw + -5.3125rem, 5.9375rem)
  }

  .where-find .btn-wrap {
    margin-top: calc(0px - clamp(1.25rem, 3.75vw + -2.125rem, 2.375rem))
  }
}

// @media only screen and (max-width: 1279.98px) {
//     .decarbonization-wake .dw-wrap {
//         margin-inline: -2rem
//     }

//     .decarbonization-wake .dw-image-wrap {
//         padding-inline: 2rem
//     }
// }

@media only screen and (max-width: 1023.98px) {
  // .primary-menu {
  //   flex-direction: column
  // }

  // .primary-menu>.menu-item:after {
  //   top: 0.3125rem;
  //   left: -2rem;
  //   width: 1.5rem;
  //   height: 0.4375rem
  // }

  // .primary-menu>.menu-item:last-child {
  //   margin-top: -0.3125rem;
  //   margin-left: -3.375rem;
  //   margin-right: -2.5625rem
  // }

  // .primary-menu>.menu-item:last-child a {
  //   padding: 1.6875rem 3.5625rem
  // }


  // .decarbonization-wake .dw-wrap {
  //     margin-inline: -1rem
  // }

  // .decarbonization-wake .dw-image-wrap {
  //     padding-inline: 6%
  // }

  // .services .editor br {
  //     display: none
  // }

  // .services .col-text {
  //     padding-inline: 1.25rem;
  //     margin-bottom: 3rem
  // }

}

@media only screen and (max-width: 767.98px) {
  // .field-group {
  //   flex-direction: column
  // }

  // .form .form-actions {
  //   flex-direction: column-reverse;
  //   align-items: flex-start
  // }

  // .decarbonization-wake .dw-image-wrap {
  //     padding-inline: 21%
  // }

  // .faq .faq__title small {
  //     display: block
  // }

  // .services {
  //     padding-bottom: 5.625rem
  // }

  .where-find .where-find__gallery {
    margin-inline: calc(0px - clamp(1.25rem, 13.9949vw + -2.0301rem, 4.6875rem))
  }
}

// @media only screen and (min-width: 768px) and (max-width: 1023.98px) {
//     .services .col-slider {
//         padding-inline: 1.5rem
//     }
// }