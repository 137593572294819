:root {
    --blue-light: #54c9ee;
    --blue-active: #007094;
    --blue-corp: #003e52;
    --white-prime: #f5f5f5;
    --gray-light: #e6e6e6;
    --gray-medium: #c8c8c8;
    --gray-600: #9b9898;
    --gray-heavy: #757575;
    --black-prime: #1f1f1f;
    --green-light: #29a790;
    --green-heavy: #105656;
    --fail: #ff5757;
    --success: #009e3b;
    --black: #000;
    --white: #fff;
    --form-invalid-color: #ff5757;
    --form-valid-color: #009e3b;
    --ff-base: 'Exo 2', sans-serif;
    --ff-accent: 'Inter', sans-serif;
    --ff-third: 'Montserrat', sans-serif;
    --fz-base: 1rem;
    --body-font-family: var(--ff-base);
    --body-font-size: var(--fz-base);
    --max-width: 100%;
    --max-wrapper-width: 100%;
    --gutter: 1rem;
    --header-color: #000;
    --body-color: #000;
    --body-bgc: var(--white-prime);
    --box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15)
}



*,
*::before,
*::after {
    box-sizing: border-box
}

:focus {
    outline: none
}

html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
}

body {
    margin: 0;
    overflow-x: hidden;
    color: var(--body-color);
    font-family: var(--body-font-family);
    font-size: var(--body-font-size);
    font-smooth: always;
    font-weight: 400;
    line-height: 1.2;
    text-align: left;
    background-color: var(--body-bgc);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
    display: block
}

[tabindex="-1"]:focus:not(:focus-visible) {
    outline: 0 !important
}

hr {
    height: 0;
    box-sizing: content-box;
    overflow: visible
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin-top: 0;
    margin-bottom: 0
}

ol,
ul,
dl {
    margin: 0;
    padding-left: 0;
    list-style: none
}

b,
strong {
    font-weight: bold
}

a {
    text-decoration: none;
    cursor: pointer
}

a:hover {
    text-decoration: none
}

button {
    border-radius: 0;
    cursor: pointer
}

button:focus {
    outline: 0 none
}

input,
button,
select,
textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit
}

input,
button {
    overflow: visible
}

button,
select {
    text-transform: none
}

[role='button'] {
    cursor: pointer
}

select {
    word-wrap: normal
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
    padding: 0;
    border-style: none
}


html {
    scroll-behavior: smooth
}

body {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: calc(100vh - var(--vh-offset, 0px));
    text-rendering: geometricPrecision;
    animation: fadein 1.25s
}

body.is-open {
    overflow: hidden
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0
}

img[data-lazyloaded] {
    opacity: 0
}

img.litespeed-loaded {
    transition: opacity 0.5s linear 0.2s;
    opacity: 1
}

sup {
    display: inline-flex;
    font-size: 1em;
    vertical-align: top;
    margin-left: 0.1em
}