@import "../../globals/commons.scss";
//**********************Modal**************************
.modal-dialog {
    max-width: 80%;
    height: 100%;
    margin: 0 auto;
}

.modal-content {
    height: 95%;
    width: 70%;
    // overflow: auto;
}

.img-modal-decokatsu img {
    max-width: 100%;
    height: auto;
}

//******************Dots da Paginação********************
.slick-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.slick-dots li {
    width: 10px;
    height: 10px;
}

.slick-dots li button {
    background: #007094;
    border-radius: 50%;
    opacity: 1;
    width: 10px;
    height: 10px;
}

.slick-dots li.slick-active button {
    background: var(--modal-color);
}

.slick-dots li.slick-active button:before,
.slick-dots li button:before {
    color: #00000000;
}

.container-img-decokatsu {
    height: 76vh;
}

//******************Setas Paginação********************
.carousel-control-next, .carousel-control-prev {
    position: absolute;
    top: 6%;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 5%;
    padding: 0;
    color: #fff;
    text-align: center;
    background: 0 0;
    border: 0;
    opacity: .5;
    transition: opacity .15s ease;
}

.carousel-control-prev,
.carousel-control-next {
    justify-content: space-around;
}

.carousel-control-prev-icon {
    background-image: url(../../assets/img/seta-esquerda.png);
    width: 1.5rem;
    height: 1.5rem;
}

.carousel-control-next-icon {
    background-image: url(../../assets//img/seta-direita.png);
    width: 1.5rem;
    height: 1.5rem;
}


//******************Responsivos********************
@media only screen and (max-width: 1300px) {
    .modal-dialog {
        max-width: 100%;
    }
  }
  
