@import "../../globals/commons.scss";

.site-main {
    position: relative;
    max-width: var(--max-width);
    margin: 0 auto;
    overflow: hidden;
    flex: 1;
    width: 100%;
    padding-bottom: 0px;
}

.main-hero {
    position: relative;
    z-index: 0;
    height: calc(100vh - var(--vh-offset, 0px));
}

.main-hero .main-hero__media {
    position: absolute;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 0;
    overflow: hidden;
}

.main-hero .main-hero__media img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top center;
}

.main-hero .main-hero__content {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    margin-bottom: 1.5625rem;
    padding-inline: clamp(2.25rem, 19.3384vw + -2.2824rem, 7rem);
}

.main-hero .main-hero__content h1,
.main-hero .main-hero__content h2 {
    color: var(--white-prime);
    text-transform: uppercase;
    line-height: 1.2;
}

.main-hero .main-hero__content h1 {
    margin-bottom: clamp(-0.375rem, 4.0712vw + -1.3292rem, 0.625rem);
    font-weight: 600;
    font-size: clamp(4.75rem, 3.0534vw + 4.0344rem, 5.5rem);
    line-height: 0.9;
}

.main-hero .main-hero__content h2 {
    font-weight: 300;
    font-size: clamp(1.9594rem, 7.7226vw + 0.1494rem, 3.8563rem);
}

.main-hero .main-hero__content .btn {
    margin-top: clamp(1.875rem, 2.5445vw + 1.2786rem, 2.5rem);
}

.wrapper-sections {
    position: relative;
    display: grid;
    padding-inline: var(--gutter);
    grid-template-rows: repeat(4, auto);
    margin-bottom: clamp(1.25rem, 7.1247vw + -0.4198rem, 3rem);
    overflow: hidden;
}

.wrapper-sections .wrapper-sections__image {
    position: absolute;
    top: -1px;
    bottom: 0;
    z-index: 2;
    display: flex;
}

.wrapper-sections .wrapper-sections__pic {
    display: flex;
    justify-content: center;
    overflow: hidden;
    flex: 1;
}

.wrapper-sections .wrapper-sections__pic img {
    transform-origin: top center;
    margin-top: -1px;
    width: 125%;
    height: 125%;
    object-fit: cover;
}

.about {
    position: relative;
    z-index: 3;
    padding-top: clamp(4.625rem, 19.8473vw + -0.0267rem, 9.5rem);
    padding-bottom: clamp(7.5rem, 22.9008vw + 2.1326rem, 13.125rem);
    grid-row: 1;
}

.about .wrap {
    display: grid;
}

.about .col-logos {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 2.5rem;
    margin-top: 6.875rem;
}

.about .col-logos img {
    max-width: 10.125rem;
    width: 100%;
    height: auto;
    filter: drop-shadow(0 8px 16px rgba(0, 0, 0, 0.6));
}

.about .title {
    margin-bottom: 1.5rem;
}

.about .subtitle {
    margin-bottom: 1.5rem;
    color: var(--blue-active);
    font-size: clamp(1.6875rem, 0.2545vw + 1.6279rem, 1.75rem);
    font-weight: 600;
    line-height: 1.3;
    text-transform: uppercase;
    text-wrap: balance;
}

.about .editor p {
    color: var(--black-prime);
    font-family: var(--ff-accent);
    font-size: 1rem;
    letter-spacing: 0.01em;
    line-height: 1.5;
    text-wrap: balance;
}

.title {
    --title-color: var(--blue-active);
    color: var(--title-color);
    font-size: 0.75rem;
    font-weight: 600;
    letter-spacing: 0.3em;
    text-transform: uppercase;
    vertical-align: baseline;
}

.title:after {
    content: "";
    display: inline-flex;
    width: 1rem;
    height: 0.125rem;
    background-color: currentColor;
}

.editor {
    font-family: var(--ff-accent);
}

.our-mission {
    position: relative;
    z-index: 0;
    grid-row: 2;
    padding-block: clamp(2.8125rem, 37.4046vw + -5.9542rem, 12rem);
    background-color: var(--green-heavy);
}

.our-mission .wrap {
    display: grid;
}

.our-mission .title-accent {
    margin-bottom: clamp(1.125rem, 1.5267vw + 0.7672rem, 1.5rem);
    color: var(--white-prime);
    font-size: clamp(1.5rem, 2.0356vw + 1.0229rem, 2rem);
    font-weight: 500;
    text-transform: uppercase;
}

.our-mission .title-accent span {
    color: var(--blue-light);
}

.our-mission .editor {
    font-family: var(--ff-base);
}

.our-mission .editor p {
    color: var(--white-prime);
    font-size: 1.25rem;
    line-height: 1.6;
    letter-spacing: -0.04em;
    text-wrap: pretty;
}

.carbon-credit {
    position: relative;
    z-index: 4;
    grid-row: 3;
}

.carbon-credit .wrap {
    display: grid;
    grid-row-gap: 3.75rem;
}

.carbon-credit .title {
    margin-bottom: 1.5rem;
}

.carbon-credit .editor {
    font-family: var(--ff-base);
    padding-right: 0.75rem;
}

.carbon-credit .editor p {
    color: var(--blue-active);
    font-size: clamp(1.5rem, 1.0178vw + 1.2615rem, 1.75rem);
    line-height: 1.3;
    text-wrap: pretty;
}

.carbon-credit .editor p strong {
    font-weight: 600;
}

.carbon-credit .col-card {
    position: relative;
    display: grid;
    grid-template-rows: 1fr auto;
    align-items: end;
    justify-content: center;
    gap: 1.5rem;
    /* &:before
      content ''
      position absolute
      z-index -1
      top 50%
      left 50%
      width 110%
      aspect-ratio 1
      translate -50% -50%
      border-radius 100%
      background radial-gradient(66.69% 57.9% at 50% 50%, rgba(0, 0, 0, .5) 0%, rgba(0, 0, 0, .47) 50%, rgba(217,217,217,0) 100%)
      background-blend-mode multiply
      mix-blend-mode multiply
      filter blur(20px)

      +above($md)
        top 70%
        width 90% */
}

.carbon-credit .col-card .carbon-credit__icon {
    width: 4.5rem;
    height: auto;
    margin-inline: auto;
}

.carbon-credit .col-card .carbon-credit__icon svg {
    width: 100%;
    height: 100%;
}

.carbon-credit .col-card .carbon-credit__desc {
    max-width: 93%;
    margin-inline: auto;
    padding-inline: 2rem;
    color: var(--white-prime);
    text-shadow: 0 0 5px var(--black);
}

.carbon-credit .col-card .carbon-credit__desc p {
    font-family: var(--ff-accent);
    font-size: 0.875rem;
    letter-spacing: 0.01em;
    line-height: 1.5;
}

.carbon-calculator {
    position: relative;
    z-index: 5;
    grid-row: 4;
    padding: clamp(2.375rem, 1.0178vw + 2.1365rem, 2.625rem) clamp(1.5rem, 6.6158vw + -0.0506rem, 3.125rem);
    background-color: var(--green-heavy);
}

.carbon-calculator .wrap {
    display: grid;
    grid-row-gap: 2.625rem;
}

.carbon-calculator .col-actions {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
    gap: 2rem 1.5rem;
}

.carbon-calculator .col-actions .btn {
    width: 100%;
    min-height: 3rem;
    max-width: 34.625rem;
}

.carbon-calculator .title-accent {
    margin-bottom: 1.25rem;
    color: var(--blue-light);
    font-size: clamp(1.5rem, 1.0178vw + 1.2615rem, 1.75rem);
    font-weight: 600;
    text-transform: uppercase;
}

.carbon-calculator .editor p {
    color: var(--white-prime);
    font-size: 1rem;
    letter-spacing: 0.03em;
    line-height: 1.5;
    text-wrap: pretty;
}

@media only screen and (min-width: 768px) {
    // .site-main {
    //     padding-bottom: clamp(6.25rem, 14.5833vw + -0.75rem, 12.375rem)
    // }

    .service-card .service-card__title {
        line-height: 120%;
    }

    .about {
        grid-column: 1 / span 8;
    }

    .about .wrap {
        grid-template-columns: repeat(8, minmax(0, 1fr));
        grid-column-gap: 1rem;
    }

    .about .col-text {
        grid-column: 1 / span 4;
    }

    .about .col-logos {
        grid-column: 5 / span 3;
    }

    .about .col-logos img {
        max-width: clamp(10.125rem, 7.1429vw + 6.6964rem, 13.125rem);
    }

    .about .title {
        margin-bottom: clamp(1.5rem, 0.8929vw + 1.0714rem, 1.875rem);
    }

    .about .subtitle {
        margin-bottom: clamp(1.25rem, 2.9762vw + -0.1786rem, 2.5rem);
        font-size: clamp(1.75rem, 1.7857vw + 0.8929rem, 2.5rem);
    }

    .about .editor p {
        font-size: clamp(1rem, 0.5952vw + 0.7143rem, 1.25rem);
    }

    .carbon-calculator {
        grid-column: 1 / span 8;
        padding: clamp(2.625rem, 3.2738vw + 1.0536rem, 4rem) clamp(3.125rem, 3.2738vw + 1.5536rem, 4.5rem);
    }

    .carbon-calculator .wrap {
        grid-column-gap: 1rem;
    }

    .carbon-calculator .title-accent {
        font-size: clamp(1.75rem, 0.5952vw + 1.4643rem, 2rem);
        margin-bottom: clamp(0.75rem, 2.5vw + -1.5rem, 1.5rem);
    }

    .carbon-credit {
        grid-column: 1 / span 8;
        padding-block: 13.4375rem 10.75rem;
    }

    .carbon-credit .wrap {
        grid-template-columns: repeat(8, minmax(0, 1fr));
        grid-column-gap: 1rem;
    }

    .carbon-credit .col-text {
        grid-column: 1 / span 4;
        padding-inline: 1.5rem;
    }

    .carbon-credit .title {
        margin-bottom: clamp(1.5rem, 1.1905vw + 0.9286rem, 2rem);
    }

    .carbon-credit .editor p {
        font-size: clamp(1.75rem, 1.7857vw + 0.8929rem, 2.5rem);
    }

    .carbon-credit .col-card {
        grid-column: 5 / span 3;
        gap: clamp(1.5rem, 1.6369vw + 0.7143rem, 2.1875rem);
    }

    .carbon-credit .col-card .carbon-credit__icon {
        width: clamp(4.5rem, 3.2738vw + 2.9286rem, 5.875rem);
    }

    .decarbonization-wake {
        margin-bottom: clamp(9rem, 10.5655vw + 3.9286rem, 13.4375rem);
    }

    .decarbonization-wake .dw-title {
        margin-bottom: clamp(3.75rem, 6.25vw + 0.75rem, 6.375rem);
        font-size: clamp(1.5rem, 1.7857vw + 0.6429rem, 2.25rem);
    }

    // .faq .faq__title small {
    //     font-size: clamp(1.25rem, 1.5625vw + 0.5rem, 1.9063rem)
    // }

    // .faq .faq__title small:before {
    //     content: '- '
    // }

    .main-hero .main-hero__content {
        padding-inline: clamp(7rem, 4.4643vw + 4.8571rem, 8.875rem);
    }

    .main-hero .main-hero__content h1 {
        font-size: clamp(5.5rem, 1.1905vw + 4.9286rem, 6rem);
    }

    .main-hero .main-hero__content h1 wbr {
        display: none;
    }

    .main-hero .main-hero__content h2 {
        font-size: clamp(3.8563rem, 0.3423vw + 3.692rem, 4rem);
    }

    .our-mission {
        grid-column: 1 / span 8;
        padding-block: clamp(12rem, 11.3095vw + 6.5714rem, 16.75rem);
    }

    .our-mission .wrap {
        grid-template-columns: repeat(8, minmax(0, 1fr));
        grid-column-gap: 1rem;
    }

    .our-mission .col-text {
        grid-column: 1 / span 4;
        padding-inline: 1.5rem;
    }

    .our-mission .title-accent {
        font-size: clamp(2rem, 1.1905vw + 1.4286rem, 2.5rem);
    }

    .our-mission .editor {
        padding-right: 15%;
    }

    .our-mission .editor p {
        font-size: clamp(1.25rem, 1.7857vw + 0.3929rem, 2rem);
    }

    .services {
        margin-bottom: clamp(5.9375rem, 6.6964vw + 2.7232rem, 8.75rem);
    }

    .services .title {
        margin-bottom: clamp(1.25rem, 1.4881vw + 0.5357rem, 1.875rem);
    }

    .services .editor {
        font-size: clamp(1.75rem, 1.7857vw + 0.8929rem, 2.5rem);
    }

    .wrapper-sections {
        grid-template-columns: repeat(8, minmax(0, 1fr));
        grid-column-gap: 1rem;
        background-color: var(--white-prime);
    }

    .wrapper-sections .wrapper-sections__image {
        grid-row: 1 / span 5;
        grid-column: 5 / span 3;
    }
}

@media only screen and (min-width: 1024px) {
    .wrapper {
        padding-inline: calc(var(--gutter) * 2);
    }

    // .primary-menu {
    //     gap: 1.875rem
    // }

    // .primary-menu>.menu-item:after {
    //     bottom: 0;
    //     left: 50%
    // }

    // .primary-menu>.menu-item a {
    //     font-size: 0.875rem
    // }

    .swiper {
        --swiper-navigation-sides-offset: 1.875rem;
    }

    .title {
        font-size: clamp(0.75rem, 0.5952vw + 0.4643rem, 1rem);
    }

    .tb-card {
        padding-left: clamp(4rem, 1.4881vw + 3.2857rem, 4.625rem);
    }

    .about {
        grid-column: span 10/-2;
    }

    .about .wrap {
        grid-template-columns: repeat(10, minmax(0, 1fr));
    }

    .about .col-text {
        grid-column: 1 / span 5;
    }

    .about .col-logos {
        grid-column: 7 / span 4;
    }

    .carbon-calculator {
        grid-column: span 12/-1;
        padding: 4rem 4.5rem;
    }

    .carbon-calculator .wrap {
        grid-template-columns: repeat(12, minmax(0, 1fr));
    }

    .carbon-calculator .col-text {
        grid-column: 1 / span 6;
    }

    .carbon-calculator .col-actions {
        grid-column: 8 / span 5;
    }

    .carbon-calculator .col-actions .btn {
        max-width: 21rem;
    }

    .carbon-credit {
        grid-column: span 10/-2;
    }

    .carbon-credit .wrap {
        grid-template-columns: repeat(10, minmax(0, 1fr));
    }

    .carbon-credit .col-text {
        grid-column: 1 / span 5;
    }

    .carbon-credit .col-card {
        grid-column: 7 / span 4;
    }

    .decarbonization-wake .dw-slider img {
        max-height: 25rem;
    }

    // .faq .faq__title {
    //     grid-column: 2/span 10
    // }

    // .faq .faq__content {
    //     grid-template-columns: repeat(4, minmax(0, 1fr));
    //     gap: 3.125rem;
    //     grid-column: 2/span 10
    // }

    // .faq .wrapper {
    //     display: grid;
    //     grid-template-columns: repeat(12, minmax(0, 1fr));
    //     gap: var(--gutter)
    // }

    .main-hero .main-hero__content .btn {
        display: none;
    }

    .our-mission {
        grid-column: span 12/-1;
    }

    .our-mission .wrap {
        grid-template-columns: repeat(12, minmax(0, 1fr));
    }

    .our-mission .col-text {
        grid-column: 2 / span 5;
    }

    .services .wrapper {
        display: grid;
        grid-template-columns: repeat(12, minmax(0, 1fr));
        gap: var(--gutter);
    }

    .services .col-text {
        grid-column: 2 / span 4;
        padding-top: 6.625rem;
        margin-right: -1.25rem;
    }

    .services .col-slider {
        grid-column: 7 / span 6;
    }

    .wrapper-sections {
        grid-template-columns: repeat(12, minmax(0, 1fr));
        padding-inline: calc(var(--gutter) * 2);
    }

    .wrapper-sections .wrapper-sections__image {
        grid-column: 8 / span 4;
    }

    .certifications .certifications__items {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }
}

@media only screen and (min-width: 1280px) {
    .carbon-calculator .col-actions {
        grid-column: 8 / span 4;
    }
}

@media only screen and (min-width: 1440px) {
    .wrapper {
        padding-inline: clamp(2rem, 4.7917vw + -2.3125rem, 3.4375rem);
    }

    .tb-card {
        padding-inline: clamp(4.625rem, 4.5833vw + 0.5rem, 6rem);
    }

    .about {
        padding-top: clamp(9.5rem, 28.75vw + -16.375rem, 18.125rem);
        padding-bottom: clamp(13.125rem, 25vw + -9.375rem, 20.625rem);
    }

    .carbon-calculator {
        padding-inline: clamp(4.5rem, 5.8333vw + -0.75rem, 6.25rem);
    }

    .carbon-calculator .editor p {
        font-size: clamp(1rem, 0.8333vw + 0.25rem, 1.25rem);
    }

    .carbon-credit .col-card .carbon-credit__desc {
        max-width: 70%;
    }

    .main-hero .main-hero__content {
        padding-inline: clamp(8.875rem, 13.75vw + -3.5rem, 13rem);
    }

    .main-hero .main-hero__content h1 {
        font-size: clamp(6rem, 4.7917vw + 1.6875rem, 7.4375rem);
    }

    .main-hero .main-hero__content h2 {
        font-size: clamp(4rem, 3.3333vw + 1rem, 5rem);
    }

    .our-mission {
        padding-block: clamp(12rem, 25.625vw + -11.0625rem, 19.6875rem);
    }

    .wrapper-sections {
        padding-inline: clamp(2rem, 4.7917vw + -2.3125rem, 3.4375rem);
    }

    .page-template-salva .section-header .section-header__title {
        width: clamp(15.625rem, 52.0833vw + -31.25rem, 31.25rem);
    }

    .section-header {
        margin-block: clamp(3.125rem, 10.4167vw + -6.25rem, 6.25rem);
    }

    .section-header .section-header__title {
        width: clamp(23.875rem, 79.5833vw + -47.75rem, 47.75rem);
    }

    .future-design {
        margin-block: clamp(3.625rem, 11.875vw + -7.0625rem, 7.1875rem);
    }

    .future-design:after {
        width: clamp(29.8125rem, 99.375vw + -59.625rem, 59.625rem);
        height: 2px;
    }

    .future-design .title {
        margin-bottom: clamp(2.1875rem, 7.2917vw + -4.375rem, 4.375rem);
        font-size: clamp(4.8125rem, 16.0417vw + -9.625rem, 9.625rem);
    }

    .future-design .editor {
        font-size: clamp(1.6875rem, 5.4167vw + -3.1875rem, 3.3125rem);
        margin-bottom: clamp(3.5rem, 11.6667vw + -7rem, 7rem);
    }

    .future-design .future-design__embed-video {
        margin-right: calc(0px - clamp(4.6875rem, 15.625vw + -9.375rem, 9.375rem));
        margin-bottom: clamp(3rem, 10.4167vw + -6.375rem, 6.125rem);
        margin-left: calc(0px - clamp(4.6875rem, 15.625vw + -9.375rem, 9.375rem));
    }

    .future-design .yt-btn-player svg {
        width: clamp(6rem, 17.5vw + -9.75rem, 11.25rem);
    }

    .certifications {
        margin-bottom: clamp(4.875rem, 16.25vw + -9.75rem, 9.75rem);
    }

    .certifications .wrapper {
        padding-inline: clamp(3.75rem, 12.5vw + -7.5rem, 7.5rem);
    }

    .certifications .certifications__header {
        padding: clamp(1.5rem, 2.2917vw + -0.5625rem, 2.1875rem) clamp(3.75rem, 6.25vw + -1.875rem, 5.625rem);
        margin-inline: auto;
        border-width: 2px;
        border-radius: clamp(2rem, 6.4583vw + -3.8125rem, 3.9375rem) clamp(2rem, 6.4583vw + -3.8125rem, 3.9375rem) 0 0;
    }

    .certifications .certifications__header .title {
        font-size: clamp(2rem, 6.4583vw + -3.8125rem, 3.9375rem);
    }

    .certifications .certifications__content {
        padding: clamp(3.4375rem, 11.4583vw + -6.875rem, 6.875rem) clamp(3.4375rem, 11.4583vw + -6.875rem, 6.875rem)
            clamp(2.375rem, 7.5vw + -4.375rem, 4.625rem);
        border-width: 2px;
        border-radius: clamp(2rem, 6.4583vw + -3.8125rem, 3.9375rem);
    }

    .where-find .wrapper {
        padding-inline: clamp(8.75rem, 29.1667vw + -17.5rem, 17.5rem);
    }

    .where-find .title {
        font-size: clamp(3.5rem, 11.875vw + -7.1875rem, 7.0625rem);
    }

    .where-find .headline {
        margin-bottom: clamp(1.5rem, 5vw + -3rem, 3rem);
        font-size: clamp(1.6875rem, 5.4167vw + -3.1875rem, 3.3125rem);
    }

    .where-find .btn-wrap {
        margin-top: calc(0px - clamp(1.25rem, 3.75vw + -2.125rem, 2.375rem));
    }
}

@media only screen and (max-width: 1023.98px) {
    .carbon-calculator .col-actions {
        align-items: center;
    }

    .decarbonization-wake .dw-wrap {
        margin-inline: -1rem;
    }

    .decarbonization-wake .dw-image-wrap {
        padding-inline: 6%;
    }

    .main-hero .main-hero__content {
        padding-top: 3.125rem;
    }

    .services .editor br {
        display: none;
    }

    .services .col-text {
        padding-inline: 1.25rem;
        margin-bottom: 3rem;
    }
}

@media only screen and (max-width: 767.98px) {
    .field-group {
        flex-direction: column;
    }

    .form .form-actions {
        flex-direction: column-reverse;
        align-items: flex-start;
    }

    .service-card .btn {
        width: 100%;
    }

    .about .wrap {
        padding-inline: 1.25rem;
    }

    .about .col-text {
        margin-top: -4.625rem;
        margin-inline: -2.25rem;
        padding-top: 4.625rem;
        padding-inline: 2.25rem;
        padding-bottom: 6rem;
        background-color: var(--white);
    }

    .carbon-credit {
        margin-bottom: 7.5rem;
    }

    .carbon-credit .col-text {
        padding: 2.25rem 1.5rem 3.25rem;
        background-color: var(--white-prime);
    }

    .decarbonization-wake .dw-image-wrap {
        padding-inline: 21%;
    }

    .main-hero .main-hero__content {
        padding-top: 6.25rem;
    }

    .main-hero .main-hero__content h1 {
        margin-bottom: 0.75rem;
    }

    .our-mission {
        padding-inline: 1.5rem;
        margin-bottom: 7.3125rem;
    }

    .services {
        padding-bottom: 5.625rem;
    }

    .wrapper-sections {
        padding-bottom: 1.5rem;
    }

    .wrapper-sections .wrapper-sections__image {
        top: 50vh;
        left: 0;
        z-index: -1;
        width: 100%;
    }

    .where-find .where-find__gallery {
        margin-inline: calc(0px - clamp(1.25rem, 13.9949vw + -2.0301rem, 4.6875rem));
    }
}

@media only screen and (min-width: 768px) and (max-width: 1023.98px) {
    .about .col-text {
        padding-inline: 1.5rem;
    }
}

@media only screen and (min-width: 654px) {
    .main-hero__content br {
        display: none;
    }
}
