@import "../../globals/commons.scss";

//**********************Footer**************************


.container-footer_safecarbon {
    padding-block: clamp(3.25rem, 1.5267vw + 2.8922rem, 3.625rem);
    background-color: var(--green-heavy)
}

//**********************Logo**************************
.footer-logo_safecarbon {
    width: clamp(6.1875rem, 3.8168vw + 5.2929rem, 7.125rem);
    height: auto
}

.footer-logo_safecarbon svg {
    width: 100%;
    height: 100%
}

.footer-logo_safecarbon svg path {
    fill: var(--white-prime)
}

//**********************Menus**************************
.footer-menus_safecarbon {
    width: 100%;
    max-width: var(--max-wrapper-width);
    margin-right: auto;
    margin-left: auto;
    padding-inline: var(--gutter);
    display: flex
}

.footer-menu {
    display: flex;
    align-items: center;
    gap: 1.5rem
}

.menu-item {
    color: var(--white-prime);
    font-size: clamp(0.625rem, 0.7634vw + 0.4461rem, 0.8125rem);
    font-weight: 500;
    letter-spacing: 0.65px;
    text-transform: uppercase;
    transition: color cubic-bezier(0.4, 0, 0.2, 1) 0.15s
}

.menu-item:hover {
    color: var(--blue-light)
}

//**********************Email**************************
address {
    margin-bottom: 0;
    display: block;
    unicode-bidi: isolate;
}

.footer__address {
    padding-inline: var(--gutter);
    margin-top: 1.25rem;
    color: var(--gray-medium);
    font-family: var(--ff-accent);
    font-size: 0.75rem;
    font-style: normal;
    line-height: 1.8;
    text-align: center;
}

.footer__address a {
    color: var(--gray-medium)
}

.footer__address a:hover {
    color: var(--blue-light)
}


//**********************Telas**************************
@media only screen and (min-width: 1024px) {
    .footer-menus_safecarbon {
        padding-inline: calc(var(--gutter) * 2)
    }

    .container-footer_safecarbon .footer-menus_safecarbon {
        align-items: center;
        justify-content: space-between;
        gap: 2rem;
    }

    .container-footer_safecarbon .footer-logo_safecarbon {
        margin-right: 2.5rem
    }

    .container-footer_safecarbon .main-footer__address {
        padding-inline: calc(var(--gutter) * 2)
    }


}

@media only screen and (max-width: 1023.98px) {
    .container-footer_safecarbon .footer-menus_safecarbon {
        flex-direction: column;
        align-items: center
    }

    .container-footer_safecarbon .footer-logo_safecarbon {
        margin-bottom: 1.5rem
    }

    .container-footer_safecarbon .footer-menu {
        flex-direction: column;
        gap: 0.75rem
    }

    .container-footer_safecarbon .btn.btn-contato {
        display: none
    }

    .container-footer_safecarbon .footer-menus_safecarbon {
        flex-direction: column;
        align-items: center
    }

    // .container-footer_safecarbon .footer-navbar {
    //     margin-bottom: 1.5rem
    // }

    .container-footer_safecarbon .footer-menu {
        flex-direction: column;
        gap: 0.75rem
    }

    .container-footer_safecarbon .btn.btn-contato {
        display: none
    }

}

@media only screen and (min-width: 1440px) {
    .footer-menus_safecarbon {
        padding-inline: clamp(2rem, 4.7917vw + -2.3125rem, 3.4375rem)
    }

    .container-footer_safecarbon .footer-menus_safecarbon {
        padding-inline: clamp(2rem, 4.7917vw + -2.3125rem, 3.4375rem)
    }

}